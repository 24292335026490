import Firebase from 'firebase/app';

import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from 'firebase/app';

const firebaseConfigAjxStage = {
  apiKey: "AIzaSyA0Oc92d25OFk13NG8j4Aabx0iVHdCFuu4",
  authDomain: "ecwl-ajx-stage.firebaseapp.com",
  projectId: "ecwl-ajx-stage",
  storageBucket: "ecwl-ajx-stage.appspot.com",
  messagingSenderId: "125153826482",
  appId: "1:125153826482:web:4f54d69718241a7a651bab",
  measurementId: "G-R917JJ5PSL"
};

const firebaseConfigAjxPRD = {
  apiKey: "AIzaSyDXW42WzwUpFSqOWNUorYSvvGs3tWkx1ck",
  authDomain: "ecwl-ajx.firebaseapp.com",
  projectId: "ecwl-ajx",
  storageBucket: "ecwl-ajx.appspot.com",
  messagingSenderId: "524400075760",
  appId: "1:524400075760:web:a332cb53661fba7632b335",
  measurementId: "G-NE8592T7V4"
};

export const initializeFirebaseApp = (buildApp) => {
  let firebaseConfig = {};
  if (process.env.VUE_APP_ENVIRONMENT == 'ajx-stage') {
    firebaseConfig = firebaseConfigAjxStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'ajx-prd') {
    firebaseConfig = firebaseConfigAjxPRD;
  }

  Firebase.initializeApp(firebaseConfig);
  Firebase.auth().onAuthStateChanged(() => {
    buildApp();
  });
};

export const database = () => Firebase.firestore();

export const collectionRef = (name) => {
  return firebase.firestore().collection(name);
};

export const currentUser = () => {
  return firebase.auth().currentUser;
};

export const currentUserId = () => {
  const user = firebase.auth().currentUser;
  return user ? user.uid : undefined;
};
