<template>
  <div>
    <!-- <a href="/academy" class="nav-link">Trilha de conhecimento</a> -->
    <a slot="title" href="#" class="nav-link" @click="toggleMenu">
      <p>Trilha de conhecimento</p>
    </a>  
        
    <collapse-transition>
      <div class="show" v-show="showMenu">
          <a href="/academy" class="nav-link ml-4">Cultura AJX</a>
          <a href="/crm" class="nav-link ml-4">CRM</a> 
      </div>
    </collapse-transition>
    <a href="/academy-books" class="nav-link">Material didático</a>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
  name: 'sidebar-submenu-academy',
  components: {
    CollapseTransition,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  mounted() {
  },
};
</script>
