import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import App from './App';
import VueCoreVideoPlayer from 'vue-core-video-player'
import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);

import router from './router/index';
import store from './store/index';

import BlackDashboard from './plugins/blackDashboard';
import i18n from './i18n';
import './registerServiceWorker';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import moment from 'moment';
import VueMoment from 'vue-moment';
import VueTour from 'vue-tour';
import DatePicker from 'vue2-datepicker';
import vueVimeoPlayer from 'vue-vimeo-player';
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

import Calendar from 'v-calendar/lib/components/calendar.umd'



require('moment/locale/pt-br');
require('../node_modules/vue-birth-datepicker/dist/vueBirthDatepicker.css');
require('vue-tour/dist/vue-tour.css');
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import VueSplide from '@splidejs/vue-splide';


import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import { initializeFirebaseApp } from './firebase';
import VueCarousel from 'vue-carousel';

import vSelect from 'vue-select';


import "@/assets/css/new.css";

moment.locale('pt-br');
Vue.use(VueMoment, { moment });
Vue.use(DatePicker);
Vue.use(VueTour);
Vue.use(VueTheMask);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(vueVimeoPlayer);
Vue.use(RouterPrefetch);
//Vue.use(VueCarousel);
//Vue.use( VueSplide );
Vue.use(VueCoreVideoPlayer, {
  lang: 'pt-BR'
});

import SsrCarousel from 'vue-ssr-carousel'
Vue.component('ssr-carousel', SsrCarousel);
Vue.component('v-select', vSelect);

//Vue.use(VueCoreVideoPlayer)

Vue.filter('currency', function (value) {
  const val = parseFloat(value).toFixed(2).replace('.', ',');
  const val_to_string = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return 'R$ ' + val_to_string;
});

Vue.filter('br-date', function (value) {
  if (!value || !value.includes('/')) return value
    const [yyyy, mm, dd] = value.split(" ")[0].split('/')
    return `${dd}/${mm}/${yyyy}`
});

Vue.filter('unix-to-br-date', function (value) {
  if (!value) return ""
  moment.locale('pt-br')
  const date = moment.unix(value);
  return date.format('DD/MM/YYYY');
});

Vue.filter('unix-to-br-timestamp', function (value) {
  if (!value) return ""
  const date = moment.unix(value);
  return date.format('DD/MM/YYYY HH:mm:ss');
});

Vue.filter('truncate', function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.filter('percentage', function (value) {
  if (typeof value === 'number')
    return parseFloat(value).toFixed(2) + '%';
  else
    return "0.00"
});

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'BRL' },
};
Vue.use(VueCurrencyInput, pluginOptions);

/* eslint-disable no-new */
const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});

import "@/assets/css/new.css";


initializeFirebaseApp(() => app.$mount('#app'));
