<script>
  import newsService from '@/services/News/newsService';
  import authenticationService from '@/services/Authentication/authenticationService';
  import moment from 'moment';
  
  export default {
    name: 'ebooks-news',
    data() {
      return {
        isLoading: false,
        ebooks : [],
        startIcon: ''
      }
    },
    computed: {
      loggedInUser() {
        return this.$store.getters.isLoggedIn;
      },
      getRole() {
        return this.$store.getters.userClaim.role;
      },
    },
    mounted() {
      this.loadEbooks();
    },
    methods : {
      async openDetails(id) {
        const user = await authenticationService.getLoggedUser();
        newsService.addView(user.userId, user.userEmail, id);
        this.$router.push(`market-news-ebook-details/${id}`);
      },
      async loadEbooks() {
        try {
          this.isLoading = true;
          const checkRole = this.getRole === 'assessor' ? 'broker' : 'customer';
          const ebooks = await newsService.getAllNewsEbook('ebook', checkRole);
          ebooks.forEach((item) => {
            item.created_at = moment(item.created_at, 'DD-MM-YYYY HH:mm:ss');
          });
          this.ebooks = ebooks.sort((a,b) => b.created_at - a.created_at).slice(0, 3);
          if (checkRole === 'broker') {
            const customerBooks = await newsService.getAllNewsEbook('ebook', 'customer');
            customerBooks.forEach(ebook => ebooks.push(ebook))
          }
        } catch(err) {
          console.log(err);
        } finally {
          this.isLoading = false;
        }
      },
      openMore() {
        this.$router.push(`market-news-ebooks`) 
      },
    }
  };
  </script>

<template>
  <div class="ajx-ebooks">
    <div class="row padding-bottom">
      <div class="col-md-10">
        <h3 class="noticias-big-title">Material didático</h3>
      </div>
      <div class="col-md-2 more-details" @click="openMore">
        <label class="no-margin more">Ver Mais</label>
      </div>
    </div> 
    <div class="row" v-if="isLoading">
      <div class="col-md-12 loading-gif">
        <img src="@/assets/images/loading.gif">
      </div>
    </div>
    <div class="news">
      <div class="content-news" v-for="item in ebooks" v-bind:key="item.id">
        <div class="ebooks" v-on:click="openDetails(item.id)">
          <img :src="item.thumb_url" alt="" class="img">
          <div class="legend">
            <div class="row">
              <div class="col-md-12">
                <label class="title" :title="item.title">{{item.title | truncate(item.title, 30)}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="recorder">E-book</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .ajx-ebooks .padding-bottom {
    padding-bottom: 20px;
  }

  .ajx-ebooks .more-details {
    font-weight: normal;
    color: darkblue;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .ajx-ebooks .more {
    font-size: 0.9rem!important;
    color: darkblue;
  }

  .ajx-ebooks .no-margin {
    margin: 0px;
  }

  .ajx-ebooks .noticias-big-title {
    font-weight: bold;
    color: darkblue;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin: 0px;
  }

  .ajx-ebooks .loading-gif {
    display: flex;
    align-items: center;
    justify-content: center;
  } 

  .news {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .ajx-ebooks .content-news {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 105%;
  }

  .ajx-ebooks .ebooks {
    border-radius: 8px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    -webkit-box-shadow: 0 10px 6px -6px #777;
      -moz-box-shadow: 0 10px 6px -6px #777;
            box-shadow: 0 10px 6px -6px #777;
  }

  .ajx-ebooks .ebooks:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 10px 6px -6px #0d143c;
      -moz-box-shadow: 0 10px 6px -6px #0d143c;
            box-shadow: 0 10px 6px -6px #0d143c;
  }

  .ajx-ebooks .ebooks .img {
    border-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .ajx-ebooks .ebooks .legend {
    padding-top: 10px;
    text-align: center;
    position: absolute;
    bottom: 0px;
    height: 70px;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    left: 0px;
    background-color: rgba(0,0,0,0.4);
  }

  .ajx-ebooks .ebooks .legend .title { 
    text-align: center;
    font-size: 0.8rem;
    color: #fff;
  }

  .ajx-ebooks .ebooks .legend .recorder { 
    text-align: center;
    font-size: 0.8rem;
    color: #fff;
  }

  @media (max-width: 766px) {
    .news {
      grid-template-columns: 1fr;
    }
  }
</style>