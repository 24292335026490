/* eslint-disable require-jsdoc */
import firebase from 'firebase';
import { withLog } from '@/logger/with_log';
import PlaceholderImg from '@/assets/images/placeholder.jpeg';

export default {
  storageRef(path) {
    return firebase.storage().ref(path);
  },
  

  collection() {
    return firebase.firestore().collection('videos');
  },

  async getAll() {
      const snapshot = await this.collection().orderBy('title', 'asc').get();
      if (snapshot.empty) {
        return [];
      }
      return snapshot.docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }));
  },

  async update(id, data) {
    const c = data.title.toLowerCase();

    var array = [];
    for (let i = 1; i < c.length + 1; i++) {
      array.push(c.substring(0, i));
    }

    data.title_search = array;

    await this.collection().doc(id).update(data);
  },

  async get(id) {
    return await this.collection()
          .doc(id)
          .get()
          .then((doc) => {
            if (doc.exists) {
                let item =  doc.data();
                item.id = id;
                return item
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                return null
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            return null;
        });
  },

  async findById(id) {
    const snapshot = await withLog(() => this.collection().doc(id).get()); 
    return snapshot.data()
  },

  async add(data) {
    return await withLog(async () => {
      const docRef = this.collection().doc();
      const c = data.title.toLowerCase();

      var array = [];
      for (let i = 1; i < c.length + 1; i++) {
        array.push(c.substring(0, i));
      }

      data.title_search = array;

      const video = {
        ...data,
        id: docRef.id
      }
      
      await docRef.set(video);

      return video;
    });
  },

  async delete(id) {
    await withLog(() => this.collection().doc(id).delete());
  },

  async uploadFile(attachment, fileName, storage = 'video-content') {
    const operationalAttachment = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');
    const response = await operationalAttachment
      .child(fileNameAdjusted)
      .put(attachment);

    //console.log(response);

    return response;
  },

  async urlThumbnail(file, storage = 'video-content') {
    let urlAtt = '';
    const operationalAttachments = this.storageRef(storage);
    await operationalAttachments
      .child(file)
      .getDownloadURL()
      .then(function (url) {
        urlAtt = url;
      });

    return urlAtt;
  },

  //dashboard services
  async getMostRecentVideos(top = 3, role = 'clients') {
    let videos;
      if (role === 'master' || role === 'gestor') {
        videos = await this.collection()
          .where('isActive', "==", "Sim")
          .orderBy('changedTime', 'desc')
          .limit(top)
          .get();
      } else {
        videos = await this.collection()
          .where('isActive', "==", "Sim")
          .where('target_audience', 'array-contains', role)
          .orderBy('changedTime', 'desc')
          .limit(top)
          .get();
      }

      if (videos.empty) {
        return [];
      }

      return videos.docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }));
  },

  async getSearchVideos(term, top = 100, role = 'clients') {
    let v =  [];
    const videos = await this.collection()
      .where('isActive', "==", "Sim")
      .where('target_audience', 'array-contains', role)
      //.where("title_search", "array-contains", term.toLowerCase())
      .orderBy('changedTime', 'desc')
      .limit(top)
      .get();

      if (videos.empty) {
        return [];
      }

      let mapped = videos.docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }));

      for(let i = 0; i < mapped.length; i++) {
        if(mapped[i].title.toLowerCase().indexOf(term.toLowerCase()) != -1) {
          v.push(mapped[i])
        }
      }

      return v;
  },

  async getFavVideos(id, top = 250) {
    const videos = await this.collection()
      .where('isActive', "==", "Sim")
      .where("favs", "array-contains", id)
      .orderBy('changedTime', 'desc')
      .limit(top)
      .get();

      if (videos.empty) {
        return [];
      }

      return videos.docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }));
  },

  async getMostPopularVideos(top = 3, role = 'clients') {
    const videos = await this.collection()
      .where('isActive', "==", "Sim")
      .where('target_audience', 'array-contains', role)
      .orderBy('total_views', 'desc')
      .limit(top)
      .get();

      if (videos.empty) {
        return [];
      }

      return videos.docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }));
  },

  async getBannerVideos(top = 5) {
    const videos = await this.collection()
      .where('isActive', "==", "Sim")
      .where('show_banner', "==", true)
      .orderBy('changedTime', 'desc')
      .limit(top)
      .get();

      if (videos.empty) {
        return [];
      }

      return videos.docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }));
  },

  async addView(id, email) {
    let video = await this.get(id);

    if(!video.views) {
      video.views = [];
      video.views.push(email);
      video.total_views = parseInt(video.total_views) + parseInt(1)
    } else {
      //TODO - improvce for unique views
      if(!video.views.includes(email)) {
        video.views.push(email);
        video.total_views = parseInt(video.total_views) + parseInt(1)
      }

    }

    return await this.update(id, video);
  },

  async addLike(id, email) {
    let video = await this.get(id);

    if(!video.likes) {
      video.likes = [];
      video.likes.push(email);
      video.total_likes = parseInt(video.total_likes) + parseInt(1)
    } else {
      //TODO - improvce for unique views
      if(!video.likes.includes(email)) {
        video.likes.push(email);
        video.total_likes = parseInt(video.total_likes) + parseInt(1)
      }

    }

    return await this.update(id, video);
  },

  async addFav(id, email) {
    let video = await this.get(id);

    if(!video.favs) {
      video.favs = [];
      video.favs.push(email);
      //video.total_likes = parseInt(video.total_likes) + parseInt(1)
    } else {
      //TODO - improvce for unique views
      if(!video.favs.includes(email)) {
        video.favs.push(email);
        //video.total_likes = parseInt(video.total_likes) + parseInt(1)
      }
    }

    return await this.update(id, video);
  },

  async removeLike(id, email) {
    let video = await this.get(id);

    //TODO - improvce for unique views
    let index = video.likes.indexOf(email); 
    if(index != -1) {
      video.likes.splice(index, 1);
    }

    return await this.update(id, video);
  },

  async removeFav(id, email) {
    let video = await this.get(id);

    //TODO - improvce for unique views
    let index = video.favs.indexOf(email); 
    if(index != -1) {
      video.favs.splice(index, 1);
    }

    return await this.update(id, video);
  },

  async addNote(id, note, email) {
    let video = await this.get(id);

    if(!video.notes) {
      video.notes = []
    }

    for(let i = 0; i < video.notes.length; i++) {
      if(video.notes[i].email == email) {
        video.notes.splice(i, 1);
        break;
      }
    }

    video.notes.push({
      email : email,
      date: new Date(),
      note: note
    })

    return await this.update(id, video);
  },
  
  async getFileObjectForPlaceholder() {
    const response = await fetch(PlaceholderImg);
    const blob = await response.blob();

    const file = new File([blob], 'placeholder.jpeg', { type: blob.type });
    
    return file;
  }
};
