<script>
import attachmentsService from '@/services/Attachments/attachmentsService';
import moment from 'moment-timezone';
import getRemuneracaoAcumulada from '@/functions/getRemuneracaoAcumulada';
import getOrderCheckPointStatus from '@/functions/getOrderCheckpointStatus';
import ordersService from '@/services/Orders/ordersService';
import historyService from '@/services/History/historyService';
import hubspotService from '@/services/HubSpot/hubspotService';
import pessoasService from '@/services/Pessoas/pessoasService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ConfirmationModal from '@/components/ConfirmationModal';
import { SlideYUpTransition } from 'vue2-transitions';
import birthDatepicker from 'vue-birth-datepicker';
import VueHtml2pdf from 'vue-html2pdf';
import axios from 'axios';
import brokersService from '@/services/Brokers/brokersService';

import * as XLSX from 'xlsx';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  name: 'orders-table',
  props: {
    title: {
      type: String,
      default: () => "",
      description: 'Table title',
    },
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    type: {
      type: String,
      default: () => "",
      description: 'Table type',
      default: () => "confirmed",
    },
    isAllOrdersPage: {
      type: Boolean,
    },
    confirmedOrderOptions: {
      type: Array,
      default: () => [],
      description: 'Array with order options',
    },
    confirmedStatusOptions: {
      type: Array,
      default: () => [],
      description: 'Array with order status options',
    },
    role: {
      type: String,
      description: 'Level user',
      default: () => "investor"
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    }
  },
  components: {
    DoubleBounce,
    ConfirmationModal,
    birthDatepicker,
    SlideYUpTransition,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      original_data: [...this.data],
      sumInvestmentValue: 0,
      sumAccumulatedRemuneration: 0,
      sumAmmountConsolidated: 0,
      sumSettledPayment: 0,
      items_exportar: [],
      item_modal: {},
      item_history: [],
      processing: false,
      showSolicitarDevolucao: false,
      showInformarPagamento: false,
      showDesfazerPagamento: false,
      showAtualizarContrato: false, 
      showInformarLiquidacao: false,
      showProcessarPagamento: false,
      showDesfazerLiquidacao: false,
      showAddBroker: false,
      showCancelarCCB: false,
      showHistory: false,
      isLoading: false,
      downloadPDF: false,
      selectedStatusOrder: "Pago",
      selectedFilterSituacao: "",
      situationOptions: ["Vencido", "Pendente", "Vigente", "Liquidado"],
      selectedFilterDateOrder: "data_contratacao",
      selectedOrdenationOrder: "data_contratacao",
      orderOptions: {
        confirmed: this.confirmedOrderOptions,
        pending: [
          {
            value: "data_contratacao",
            label: "Data de Contratação",
          }
        ]
      },
      searchBroker: '',
      filterDate: {},
      added_broker: {},
      brokers: [],
      valor_liquidacao_manual: 0,
      isAddingBroker: false,
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    role() {
      let value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    displayedData() {
      this.setPages();
      const filteredData = this.data
      return this.paginate(filteredData);
    },
    originalData() {
      return this.original_data;
    }
  },
  async mounted() {
    
    this.calculateTotalValues(this.data);
    const brokers = await brokersService.getAllBrokers() || [];
    const formattedBrokers = brokers.length > 0 && brokers.map(broker => {
      const formatted = {
        id: broker.id,
        name: broker.name,
        email: broker.email,
        cpf: broker.cpf,
      };

      return formatted;
    });
    this.brokers = formattedBrokers;
    // for ( const item of this.data ) {
    //   this.items_exportar.push({
    //     investidor: item.pessoa.nome,
    //     codigo_operacao: (item.ccb && item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--',
    //     numero_ccb: item.ccb.numero_ccb || '--',
    //     valor_investimento: (item.item.value * item.item.quantity),
    //     remuneracao_acumulada: (this.remuneracaoAcumulada(item)),
    //     valor_remuneracao: (this.remuneracaoAcumulada(item) - (item.item.value * item.item.quantity)),
    //     carencia_total_lote: this.prazo
    //   });
    // }
    // for ( const item of this.data ) {
    //   this.items_exportar.push({
    //     investidor: item.pessoa.nome,
    //     codigo_operacao: (item.ccb && item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--',
    //     numero_ccb: item.ccb.numero_ccb || '--',
    //     valor_investimento: (item.item.value * item.item.quantity),
    //     remuneracao_acumulada: (this.remuneracaoAcumulada(item)),
    //     valor_remuneracao: (this.remuneracaoAcumulada(item) - (item.item.value * item.item.quantity)),
    //     carencia_total_lote: this.prazo
    //   });
    // }
    
  },
  methods: {
    onChangeSituation(event) {
      this.selectedFilterSituacao = event.target.value
    },
    onChangeOrdenation(event) {
      this.selectedOrdenationOrder = event.target.value
    },
    onChangeDateFilter(event) {
      this.selectedFilterDateOrder = event.target.value
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);

    },
    formatDate(date) {
      return moment(date).tz('America/Sao_Paulo').format('LLL');
    },
    async downloadContratoCCB(item) {
      try {
        let temp = getRemuneracaoAcumulada(null)
        let filename = item.ccb.numero_ccb + "_ContratoCCB.pdf";
        await attachmentsService.downloadAttachment(filename, 'ContratosCCB');
      }
      catch (e) {
        console.log(e);
      }
    },
    async getHistory(context) {
      try {
        this.item_history = [];
        let history;

        if (context) {
          history = await historyService.getByContextAndId(context, this.item_modal.id);
        } else {
          history = await historyService.getByContextAndId('Orders', this.item_modal.id);
        }

        console.log(history);
        await history.forEach(async (log) => {
          const _log = log.data();
          this.item_history.push(_log);
        });

        if (context === 'Orders-AddBroker') {
          this.item_history = this.item_history.reverse();
        }
        
        console.log(this.item_history);
        this.isLoading = false;
      } catch (error) { }
    },
    getRemuneracaoAcumulada(order){
      return getRemuneracaoAcumulada(order)
    },
    getOrderCheckpointStatus(order){
      return getOrderCheckPointStatus(order)
    },
    getOrderDataVencimento(order){
      return getOrderCheckPointStatus(order).vencimento
    },
    getLoadBar(checkpoint){
      return `<div title="${checkpoint.percentual}% (${checkpoint.checkpoint} de ${checkpoint.prazo_oferta} meses)" class="progress-container progress-default progress-sm"><!---->
                        <div class="progress"><span class="progress-value">${checkpoint.percentual}%</span>
                          <div role="progressbar" aria-valuenow="${checkpoint.percentual}" aria-valuemin="0" aria-valuemax="100"
                            class="progress-bar" style="width: ${checkpoint.percentual}%;"><!----></div>
                        </div>
                      </div>`;

    },
    getCompleteLoadBar(){
      return `<div title="100%" class="progress-container progress-default progress-sm"><!---->
                        <div class="progress"><span class="progress-value">100%</span>
                          <div role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                            class="progress-bar" style="width: 100%;"><!----></div>
                        </div>
                      </div>`;

    },
    openModalAtualizarContrato(item) {
      console.log({item})
      this.item_modal = item;
      this.showAtualizarContrato = true;
    },
    openModalInformarPagamento(item) {
      console.log({item})
      this.item_modal = item;
      this.item_modal.data_informacao_pagamento = ""
      this.showInformarPagamento = true;
    },
    openModalDesfazerPagamento(item) {
      this.item_modal = item;
      this.showDesfazerPagamento = true;
    },
    openModalLiquidacaoCCB(item) {
      
      item.valor_liquidacao = (item.item.quantity * item.item.value) + item.remuneracao_acumulada;
      this.item_modal = item;
      this.item_modal.data_informacao_liquidacao = ""

      console.log({item});

      this.showInformarLiquidacao = true;
    },
    openModalDesfazerLiquidacaoCCB(item){
      this.item_modal = item;
      this.showDesfazerLiquidacao = true;
    }, 
    openModalProcessarPagamentoCCB(item){
      this.item_modal = item;
      this.showProcessarPagamento = true;
    }, 
    openModalCancelarCCB(item){
      this.item_modal = item;
      this.showCancelarCCB = true;
    }, 
    openModalHistorico(item) {
      this.item_modal = item;
      this.showHistory = true;
      this.getHistory();
    },
    openModalBroker(item) {
      this.item_modal = item;
      this.showAddBroker = true;

      this.getHistory('Orders-AddBroker');
    },
    closeModal(reload = false) {
      this.item_modal = {};
      this.showSolicitarDevolucao = false;
      this.showInformarPagamento = false;
      this.showAtualizarContrato = false;
      this.showInformarLiquidacao = false;
      this.showDesfazerPagamento = false;
      this.showDesfazerLiquidacao = false;
      this.showProcessarPagamento = false;
      this.showCancelarCCB = false;
      this.showHistory = false;
      this.showAddBroker = false;
      if (reload) 
        this.$emit('reloadData');
    },
    orderData() {

      let field = this.selectedOrdenationOrder
      return this.data.sort((a, b) => a[field] - b[field])
      
    },
    filterData() {
      
      //Filtros de Broker
      const toNormalForm = function(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      }

      const multiSearchAnd = (text, searchWords) => (
        searchWords.every((el) => {
          console.log(text, searchWords,el)
          return text.match(new RegExp(el,"i"))
        })
      )

      //Filtros de data
      var unixDataInicio = null;
      var unixDataFinal = null;
      if (this.filterDate.length && this.filterDate[0] && this.filterDate[1]) {
        const [dataInicio, dataFinal] = this.filterDate;
      
        const formatISOUnixDate = (date) => {
          const [ dia, mes, ano ] = date.split('/');
          return moment([ano, mes, dia].join('-')).unix();
        } 
        
        unixDataInicio = formatISOUnixDate(dataInicio);
        unixDataFinal = formatISOUnixDate(dataFinal);
      }
      
      console.log('Datas', {unixDataInicio, unixDataFinal})
      console.log('Situacao', this.selectedFilterSituacao)
      console.log('Broker', this.searchBroker)
      this.data = this.originalData.filter(obj => {
      
        let match = true;

        if (this.selectedFilterSituacao){
          console.log('Try to filter situation')
          match = ( obj.situacao == this.selectedFilterSituacao)
          if (!match) return false;
        }

        if (unixDataInicio && unixDataFinal) {
          console.log('Try to filter dates', obj[this.selectedFilterDateOrder], unixDataInicio, unixDataFinal)
          match = ( obj[this.selectedFilterDateOrder] && 
                    (obj[this.selectedFilterDateOrder] >= unixDataInicio && obj[this.selectedFilterDateOrder] <= unixDataFinal))
          if (!match) return false;
        }
        
        if (this.searchBroker){
          console.log('Try to filter broker')
          try {
            
            if (!obj.broker || !obj.broker.name)
              return false;

            var arrSearchText = toNormalForm(this.searchBroker).split(' ');

            console.log({arrSearchText})

            match = multiSearchAnd(toNormalForm(obj.broker.name), arrSearchText)
            console.log({match})
            
            if (!obj.broker || !match) return false;
          } catch (error) {
            console.log({error});
          }
          // return false
        }
        console.log('Final match', match)
        return match
      });

      console.log('Advanced filter', this.data);

      this.data = this.orderData();

    },
    async salvarAtualizarContrato() {

      this.processing = true;
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });

      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal(true);
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }
      
      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      
      await ordersService.regenerateOrderContract(order);

      let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

      const objectHistory = {
        register_id: order.id,
        context: 'Orders',
        subject: 'Atualização de Contrato de Investimento (Regeração)',
        user_id: this.currentPessoa.pessoa_id,
        user_name: pessoa.nome,
        user_ip: user_ip,
        transaction_date: moment().unix()
      }

      await historyService.create(objectHistory);

      this.selectedIndex = '';
      this.processing = false;
      this.closeModal(true);

      this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
    },

    async salvarInformarLiquidacao() {

      if (!this.item_modal.data_informacao_liquidacao) {
        alert('Informar a data de liquidação é obrigatório');
        this.processing = false;
        return
      }
      
      this.processing = true;
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });

      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal();
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }

      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.status = 'Liquidado';
      order.liquidada = true
      
      if (this.valor_liquidacao_manual){
        order.valor_liquidacao = this.valor_liquidacao_manual;
      } else {
        order.valor_liquidacao = this.item_modal.valor_liquidacao;
      }
      

      console.log('Dt pagamento', this.item_modal.data_informacao_liquidacao)
      
      const [ dia, mes, ano ] = this.item_modal.data_informacao_liquidacao.split(' ')[0].split('/');

      order.data_liquidacao = moment([ano, mes, dia].join('-')).unix();

      await ordersService.update(order.id, order);

      let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

      const objectHistory = {
        register_id: order.id,
        context: 'Orders',
        subject: 'Liquidação de CCB (ao final da vigência)' + (this.valor_liquidacao_manual ? ` (Valor manualmente informado - R$ ${this.valor_liquidacao_manual})` : ''),
        user_id: this.currentPessoa.pessoa_id,
        user_name: pessoa.nome,
        user_ip: user_ip,
        transaction_date: moment().unix()
      }

      await historyService.create(objectHistory);

      await hubspotService.atualizarValorUltimoInvestimentoRealizadoContatoAPI(order.pessoa.id, order.item.value);

      this.selectedIndex = '';
      this.processing = false;
      this.closeModal(true);
    },
    async salvarProcessarPagamentoCCB() {

      this.processing = true;
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });

      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal();
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }

      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.pagamento_processado = true

      order.data_processamento_pagamento = moment().unix();

      //Comunicação com o servidor de pagamentos - gateway
      // curl --location 'https://ajx-payment-gateway-uednubmpia-rj.a.run.app/v1/pagamentos/pix' \
      // --header 'accept: application/json' \
      // --header 'Content-Type: application/json' \
      // --data-raw '{
      //   "valor": 46705.51,
      //   "dataPagamento": "2023-07-09",
      //   "descricao": "Liquidação de investimento em CCB",
      //   "destinatario": {
      //     "cpfCnpj": "22237423865",
      //     "nome": "Cláudio Roberto de Oliveira Filho",
      //     "tipo": "DADOS_BANCARIOS",
      //     "contaCorrente": "84501-3",
      //     "tipoConta": "CONTA_CORRENTE",
      //     "agencia": "0243",
      //     "instituicaoFinanceira": {
      //         "ispb": "60701190"
      //     }
      //   }
      // }'


      try{

        //Busca dados atualizados do cliente (Chave pix especialmente)

        let cliente = await pessoasService.findPessoaById(order.pessoa.id);
        
        const endpointBankAPI = process.env.VUE_APP_ENDPOINT_WS_BANK_API + 'pagamentos/pix';
        
        var objDestinatario = {
              cpfCnpj: cliente.documento.numero,
              nome: cliente.nome,
            }

        if (cliente.dados_complementares.dados_bancarios.chave_pix) {
          objDestinatario.tipo = 'CHAVE'
          objDestinatario.chave = cliente.dados_complementares.dados_bancarios.chave_pix;
        } else {
          objDestinatario.tipo = 'DADOS_BANCARIOS'
          objDestinatario.contaCorrente = cliente.dados_complementares.dados_bancarios.conta,
          objDestinatario.tipoConta = 'CONTA_CORRENTE',
          objDestinatario.agencia = cliente.dados_complementares.dados_bancarios.agencia,
          objDestinatario.instituicaoFinanceira = {
                nomeBanco: cliente.dados_complementares.dados_bancarios.banco
              }
        }
        await axios.post(endpointBankAPI, {
            valor: order.valor_liquidacao,
            dataPagamento: moment.unix(order.data_liquidacao).format('YYYY-MM-DD'),
            idCorrelacao: order.idCorrelacao || order.id, 
            descricao: "Liquidação de investimento em CCB - Order: " + (order.ccb.numero_ccb || ""),
            destinatario: objDestinatario
          },
          {headers: { Authorization: `Bearer ${process.env.VUE_APP_ENDPOINT_WS_BANK_API_TOKEN}` }}
          );

      }catch(error){
          console.log(Object.keys(error), error.message);
          alert('Não foi possível processar o pagamento junto ao Banco/Gateway. Entre em contato com o suporte.');
          return
      }

      order.pagamento_enviado_gateway = true

      await ordersService.update(order.id, order);

      let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

      const objectHistory = {
        register_id: order.id,
        context: 'Orders',
        subject: 'Processamento de pagamento de CCB junto ao gateway de pagamento',
        user_id: this.currentPessoa.pessoa_id,
        user_name: pessoa.nome,
        user_ip: user_ip,
        transaction_date: moment().unix()
      }

      await historyService.create(objectHistory);

      this.selectedIndex = '';
      this.processing = false;
      this.closeModal(true);
      },
    async salvarDesfazerPagamento() {
      
      this.processing = true;
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });
      
      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal();
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }

      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.status = 'Pendente';
      order.pagamento_manual = false;
      order.data_pagamento = 0

      await ordersService.update(order.id, order);

      let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

      const objectHistory = {
        register_id: order.id,
        context: 'Orders',
        subject: 'Cancelamento de Baixa Manual de Pagamento',
        user_id: this.currentPessoa.pessoa_id,
        user_name: pessoa.nome,
        user_ip: user_ip,
        transaction_date: moment().unix()
      }

      await historyService.create(objectHistory);

      await hubspotService.atualizarContatoAPI(order.pessoa.id);

      this.selectedIndex = '';
      this.processing = false;
      this.closeModal(true);
    },
    async salvarDesfazerLiquidacaoCCB() {
      this.processing = true;
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });

      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal();
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }

      var pagamento_processado = false;

      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();

      if (order.pagamento_processado)
        pagamento_processado = order.pagamento_processado;

      order.id = orderFirebase.id;
      order.status = 'Pago';
      order.liquidada = false
      order.data_liquidacao = 0;
      order.valor_liquidacao = 0;
      order.pagamento_processado = false;
      order.data_processamento_pagamento = 0;

      await ordersService.update(order.id, order);

      let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

      const objectHistory = {
        register_id: order.id,
        context: 'Orders',
        subject: 'Cancelamento de Liquidação de CCB' + (pagamento_processado ? ` (Pagamento processado anteriormente - conferir no gateway)` : ``),
        user_id: this.currentPessoa.pessoa_id,
        user_name: pessoa.nome,
        user_ip: user_ip,
        transaction_date: moment().unix()
      }

      await historyService.create(objectHistory);

      await hubspotService.atualizarContatoAPI(order.pessoa.id);

      this.selectedIndex = '';
      this.processing = false;
      this.closeModal(true);
    },
    async salvarCancelarCCB() {
      this.processing = true;
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });

      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal();
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }

      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();

      try {
        
        if(order.status == 'Liquidado'){
          alert('Operação não permitida no estado atual da ordem de pagamento.')
          return
        }
        var paymentType = ""
        order.idCorrelacao = order.idCorrelacao ? order.idCorrelacao : order.id;
        console.log('ORDER', { order });
        switch (order.tipo_pagamento) {
          case 'Pix': paymentType = 'pix'; break;
          case 'PIX': paymentType = 'pix'; break;
          case 'Boleto': paymentType = 'boletos'; break;
          case 'TED': paymentType = 'ted'; break;
        }
        const endpointBankAPI = `${process.env.VUE_APP_ENDPOINT_WS_BANK_API}cobrancas/${paymentType}/correlacoes/${order.idCorrelacao}`;

        try {
          await axios.delete(endpointBankAPI);
        } catch(err) {
          if (err.response && err.response.status === 404) {
            console.log('404 Not Found. Continuing with the rest of the code.');
          } else {
            alert('Não foi possível processar o cancelamento da ordem de pagamento junto ao Banco/Gateway. Entre em contato com o suporte.');
            this.processing = false;
          }
        }

        order.id = orderFirebase.id;
        order.status = 'Cancelado';
        order.liquidada = false
        order.data_liquidacao = 0;
        order.data_pagamento = 0;
        order.valor_liquidacao = 0;
        order.pagamento_processado = false;
        order.data_processamento_pagamento = 0;

        await ordersService.update(order.id, order);

        let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

        const objectHistory = {
          register_id: order.id,
          context: 'Orders',
          subject: 'Cancelamento Manual de CCB',
          user_id: this.currentPessoa.pessoa_id,
          user_name: pessoa.nome,
          user_ip: user_ip,
          transaction_date: moment().unix()
        }

        await historyService.create(objectHistory);

        await hubspotService.atualizarContatoAPI(order.pessoa.id);

        this.selectedIndex = '';
        this.processing = false;
        this.closeModal(true);
      } catch (error) {
        alert('Não foi possível processar o cancelamento da ordem de pagamento junto ao Banco/Gateway. Entre em contato com o suporte.');
        this.processing = false;
      }

    },
    calculateTotalValues(data) {
      if (!data || data.length === 0) {
        return;
      }
      for (const order of data) {
        const investmentValue =  order.item.quantity * order.item.value || 0;
        const accumulatedRemuneration = order.remuneracao_acumulada || 0;
        const settledPayment = order.valor_liquidacao || 0;
        
        this.sumInvestmentValue += investmentValue;
        this.sumAccumulatedRemuneration += accumulatedRemuneration;
        this.sumAmmountConsolidated += (investmentValue + accumulatedRemuneration);
        this.sumSettledPayment += settledPayment;
      }
    },
    redefineTotalValues() {
        this.sumInvestmentValue = 0;
        this.sumAccumulatedRemuneration = 0;
        this.sumAmmountConsolidated = 0;
        this.sumSettledPayment = 0;
    },
    downloadFile(format) {
      const formattedData = this.data.map(item => {
        const formattedOrder = {
          ccb: item.ccb ? item.ccb.numero_ccb : 'Código não gerado',
          oferta: item.item.description, 
          data_contratacao: item.order_date,
          cliente: (item && item.pessoa && item.pessoa.nome && item.pessoa.documento && item.pessoa.documento.numero) ? item.pessoa.nome + ' - ' + item.pessoa.documento.numero : "", 
          taxa_juros: item.financing_details.fields["taxa_juros"] ? item.financing_details.fields["taxa_juros"].value + "%" : "",
          prazo_meses_card: item.financing_details.fields["prazo_meses_card"] ? item.financing_details.fields["prazo_meses_card"].value : "",
          valor_investimento: (item.item.quantity * item.item.value || 0.00).toLocaleString('pt-br', {minimumFractionDigits: 2}),
          status: item.status || '',
          tipo_pagamento: item.tipo_pagamento || '',
          data_vencimento: item.financing_details.fields['primeiro_vencimento'].value.split('-').reverse().join('/'),
          remuneracao_acumulada: (item.remuneracao_acumulada || 0.00).toLocaleString('pt-br', {minimumFractionDigits: 2}),
          total_remuneracao_acumulada: ((item.item.quantity * item.item.value) + item.remuneracao_acumulada || 0.00).toLocaleString('pt-br', {minimumFractionDigits: 2}),
          valor_liquidado: (item.valor_liquidacao || 0.00).toLocaleString('pt-br', {minimumFractionDigits: 2}),
          ccb_status_liquidacao: item.liquidada ? 1 : 0,
          assessor: item.broker ? item.broker.name : 'Não identificado',
          gateway: item.origem_pagamento || '', 
        };
        
        return formattedOrder;
      });

      const data = XLSX.utils.json_to_sheet(formattedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');

      let fileName = 'Relatorio';
      let fileExtension = '';


      switch (format) {
        case 'xlsx':
          fileExtension = '.xlsx';
          break;
        case 'csv':
          fileExtension = '.csv';
          break;
        default:
          return;
      }

      fileName += fileExtension;
      XLSX.writeFile(wb, fileName);
    }
  },
  watch: {
    data() {
      this.redefineTotalValues();
      this.calculateTotalValues(this.data);
      this.setPages();
    },
    valor_liquidacao_manual() {
      console.log(this.valor_liquidacao_manual)
    }
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card p-2"><!---->
          <div class="card-header">
            <h5 class="card-title">{{title}} <small>({{ data.length + ' aportes' }})</small></h5>
          </div>
          <div class="card-body">
            <div v-if="role == 'gestor' && isAllOrdersPage" class="row">
              <div class="col-lg-2 col-12">
              <div class="form-group">
                <label
                  class="control-label"
                  style="cursor: default"
                  >Assessor</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Para pesquisar, digite sua busca aqui..."
                  v-model="searchBroker"
                />
              </div>
            </div>
            <div class="col-lg-2 col-12 mb-3">
              <base-input label="Filtrar por">
                <select class="form-control" @change="onChangeDateFilter($event)" v-model="selectedFilterDateOrder">
                  <option value="" selected>Selecione:</option>
                  <option
                    :value="status.value"
                    v-for="(status, index) in (data ? orderOptions[type]: [])"
                    :key="index"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </base-input>
            </div>  
            <div class="col-lg-3 col-12 mb-3">
              <base-input label="Período">
                <date-picker
                  v-model="filterDate"
                  valueType="format"
                  range
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </base-input>
            </div>
            <div class="col-lg-2 col-3">
              <base-input label="Situação">
                <select class="form-control" @change="onChangeSituation($event)" v-model="selectedFilterSituacao">
                  <option value="" selected>Selecione:</option>
                  <option
                    :value="situation"
                    v-for="(situation) in situationOptions"
                    :key="situation"
                  >
                    {{ situation }}
                  </option>
                </select>
              </base-input>
            </div>
            <div class="col-lg-2 col-3">
              <base-input label="Ordenar por">
                <select class="form-control" @change="onChangeOrdenation($event)" v-model="selectedOrdenationOrder">
                  <option value="" selected>Selecione:</option>
                  <option
                    :value="status.value"
                    v-for="(status, index) in (displayedData ? orderOptions[type]: [])"
                    :key="index"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </base-input>
            </div>
            <div class="col-lg-2 col-3 pb-3">
              <button
                class="btn btn-primary"
                @click="filterData()"
              >
                <i class="tim-icons icon-zoom-split"></i>
                Buscar
              </button>
            </div>
            </div>
            <div class="table-responsive" id="managerTable">
              <table class="table tablesorter">
                <thead class="thead-light">
                  <tr>
                    <th class="text-center" :style="{ minWidth: index == 0 ? '400px' : '100px' }" v-for="(column, index) in columns" v-bind:key="column">{{ column }}</th>
                  </tr>
                </thead>
                <tbody class="">
                  <!-- columns: ['Oferta', 'Nº CCB', 'Valor do Investimento', 'Vencimento CCB', 'Andamento', 'Remuneração Acumulada', 'Situação', 'Contrato'], -->
                  <!-- columns: ['Oferta', 'Nº CCB', 'Valor do Investimento', 'Situação', 'Boleto', 'Contrato'], -->

                  <tr v-for="(item, index) in displayedData" :key="index">
                    <td :style="{ minWidth: '400px' }" class="text-left" width="30%">
                      <b>CCB nº {{ item.ccb && item.ccb.simulacao ? item.ccb.simulacao.numero_ccb : item.ccb && item.ccb.numero_ccb ? item.ccb.numero_ccb : "" }}</b> <br/> {{ item.item.description }} - em {{ item.data_contratacao | unix-to-br-date }}
                      <br/>Cliente: <b>
                        <router-link v-if="role == 'gestor'" :to="`/orders/${item.pessoa.id}`" target= '_blank'>
                          {{ item.pessoa.nome }}
                        </router-link>
                        <span v-if="role !== 'admin'">
                          {{ item.pessoa.nome }}
                        </span>
                        
                      </b> | CPF: {{ (item.pessoa && item.pessoa.documento && item.pessoa.documento.numero ? item.pessoa.documento.numero : '') }}
                      <br/> <b>Assessor: {{ item.broker ? `${item.broker.name.split(' ')[0]} ${item.broker.name.split(' ').pop()}` : 'Não identificado'  }}</b>
                      <span class="badge badge-success ml-1"> {{ item.financing_details.fields["taxa_juros"] ? item.financing_details.fields["taxa_juros"].value + "%" : "" }}</span>  
                      <span class="badge badge-info ml-1">{{ item.financing_details.fields["prazo_meses_card"] ? item.financing_details.fields["prazo_meses_card"].value : "" }}</span> 
                    </td>
                    <td class="text-center" v-if="item.data_pagamento">
                      <b>{{ item.item.quantity * item.item.value || 0 | currency }}</b> <br/>
                      Início Vigência {{ item.data_pagamento | unix-to-br-date }} <br/>
                      (via {{ item.tipo_pagamento }} em {{ item.data_pagamento | unix-to-br-date }} - {{  item.origem_pagamento }})
                    </td>
                    <td class="text-center" v-if="!item.data_pagamento && item.tipo_pagamento == 'Boleto'">
                      {{ item.item.quantity * item.item.value || 0 | currency  }} <br/>
                      Vencimento {{item.boleto_vencimento | br-date}} <br/>
                      (via {{ item.tipo_pagamento }})
                    </td>
                    <td class="text-center" v-if="!item.data_pagamento && item.tipo_pagamento !== 'Boleto'">
                      {{ item.item.quantity * item.item.value || 0 | currency  }} <br/>
                      ({{ item.status }} via {{ item.tipo_pagamento }})
                    </td>

                    <td class="text-center" v-if="type == 'confirmed' && item.data_pagamento">{{ item.financing_details.fields['primeiro_vencimento'].value.split('-').reverse().join('/') }}</td>
                    <!-- <td class="text-center" v-if="type == 'confirmed' && item.data_pagamento">{{ getOrderDataVencimento(item) }}</td> -->
                    <td class="text-center" v-if="type == 'confirmed' && !item.data_pagamento">--</td>

                    <td class="text-center" v-if="type == 'confirmed' && item.status == 'Liquidado'" width="10%" v-html="getCompleteLoadBar()"></td>
                    <td class="text-center" v-if="type == 'confirmed' && item.status !== 'Liquidado'" width="10%" v-html="getLoadBar(getOrderCheckpointStatus(item))"></td>

                    <td class="text-center" v-if="type == 'confirmed'">
                      {{ item.remuneracao_acumulada || 0 | currency  }} <br/>
                    </td>
                    <td class="text-center" v-if="type == 'confirmed'">
                      {{(item.item.quantity * item.item.value) + item.remuneracao_acumulada || 0 | currency   }}
                    </td>
                    
                    <td class="text-center" v-if="type == 'confirmed' && !item.data_liquidacao" > {{ item.status }} </td>
                    
                    <td class="text-center" v-if="type == 'confirmed' && item.data_liquidacao"> 
                        {{ item.status }} <br/>
                        ({{item.valor_liquidacao | currency}} em {{item.data_liquidacao | unix-to-br-date}}) <br/>
                        <span v-if="item.pagamento_processado">(Pagamento processado em {{ item.data_processamento_pagamento | unix-to-br-date }})</span>
                    </td>

                    <td class="text-center" v-if="type == 'pending'"> {{ item.status }} </td>
                    <td class="text-center" v-if="isAllOrdersPage" > {{ item.broker ? item.broker.name + (isAllOrdersPage ? ' - (Comissão: R$ 0 (0%))' : '') : 'Não identificado'  }} </td>
                    
                    <td class="text-center" v-if="isAllOrdersPage" > {{ item.origem_pagamento || "Não identificado" }} </td>
                    
                    <!-- <td class="text-center" v-if="type == 'pending'"> 
                      <a 
                        :href="item.boleto_link" 
                        target="_blank" 
                        rel="noopener"
                        style="text-decoration: underline; color: #0068A5;">
                          <i title="Visualizar boleto" class="tim-icons icon-cloud-download-93 pl-2"/>
                      </a>
                    </td> -->

                    <!-- Contrato -->
                    <td class="text-center" width="10%">
                      <div style="display: inline-block; white-space: nowrap;">
                        <a class="pr-2" v-if="item.status == 'Pendente' && item.tipo_pagamento == 'Boleto'" 
                          :href="item.boleto_link" 
                          target="_blank" 
                          rel="noopener"
                          style="text-decoration: underline; color: #0068A5;">
                            <i title="Visualizar boleto" class="tim-icons icon-cloud-download-93 pl-2"/>
                        </a>
                        <i v-if="item.ccb && !item.contrato_url && item.ccb.numero_ccb && item.ccb.possui_contrato_download" title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" @click="downloadContratoCCB(item)"></i>
                        <a class="pr-2" v-if="(item.status == 'Pago' || item.status == 'Pendente') && item.contrato_url" 
                          :href="item.contrato_url" 
                          target="_blank" 
                          rel="noopener"
                          style="text-decoration: underline; color: #0068A5;">
                            <i title="Download Contrato CCB" class="tim-icons icon-cloud-download-93 pl-2"/>
                        </a>
                        <a class="pr-2" 
                            v-if="!isAllOrdersPage && 
                              role == 'gestor'"
                            title="Atualizar Contrato CCB"
                            @click="openModalAtualizarContrato(item)"
                          >
                            <i  class="tim-icons icon-cloud-upload-94 pl-2"/>
                        </a>
                        <a
                          href="#"
                          v-if="!isAllOrdersPage && 
                            role == 'gestor' && 
                            item.status !== 'Liquidado'
                          "
                          title="Cancelar CCB"
                          @click="openModalCancelarCCB(item)"
                        >
                          <i class="tim-icons icon-trash-simple pl-2"></i>
                        </a>
                        <a 
                          href="#"
                          v-if="item.status == 'Pago' && 
                            !isAllOrdersPage && role == 'gestor' && 
                            item.pagamento_manual && 
                            !item.liquidada && 
                            item.status != 'Pendente'                         
                          "
                          title="Desfazer Pagamento"
                          @click="openModalDesfazerPagamento(item)"
                        >
                          <i class="tim-icons icon-coins pl-2"></i>
                        </a>
                        <a
                          href="#"
                          v-if="item.status == 'Pago' && 
                            !isAllOrdersPage && 
                            role == 'gestor' && 
                            item.status == 'Pago' &&                        
                            !item.liquidada
                          "
                          title="Informar Liquidação"
                          @click="openModalLiquidacaoCCB(item)"
                        >
                          <i class="tim-icons icon-money-coins pl-2"></i>
                        </a>
                        <a
                          href="#"
                          v-if="item.status == 'Liquidado' && 
                            !isAllOrdersPage && role == 'gestor' 
                            // item.liquidada && !item.pagamento_processado                          
                          "
                          title="Desfazer Liquidação"
                          @click="openModalDesfazerLiquidacaoCCB(item)"
                        >
                          <i class="tim-icons icon-coins pl-2"></i>
                        </a>
                        <a
                          href="#"
                          v-if="item.status == 'Liquidado' && 
                            !isAllOrdersPage && role == 'gestor' && 
                            item.liquidada && !item.pagamento_processado
                          "
                          title="Processar pagamento"
                          @click="openModalProcessarPagamentoCCB(item)"
                        >
                          <i class="tim-icons icon-money-coins pl-2"></i>
                        </a>
                        <a
                          href="#"
                          v-if="isAllOrdersPage || role == 'gestor'"
                          title="Histórico de transações"
                          @click="openModalHistorico(item)"
                        >
                          <i class="tim-icons icon-book-bookmark pl-2"></i>
                        </a>
                      </div>
                      <!-- <i  title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" @click="downloadContratoCCB(item)"></i> -->
                    </td>
                  </tr>
                </tbody>
                </table>
                <table class="table tablesorter" v-if="!isAllOrdersPage && role == 'gestor'">
                <tfoot class="thead-light">
                  <tr>
                    <th class="text-primary"><strong></strong></th>
                    <th class="text-center"><strong>{{ type !== 'confirmed' ? 'À confirmar' : 'Investido' }}</strong></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center" v-if="type == 'confirmed'"><strong>Remuneração</strong></th>
                    <th class="text-center" v-if="type == 'confirmed'"><strong>Acumulado</strong></th>
                    <th class="text-center" v-if="type == 'confirmed'"><strong>Liquidado</strong></th>
                  </tr>
                  <tr>
                    <th class="text-primary"><strong>Total:</strong></th>
                    <th class="text-center"><strong>{{ this.sumInvestmentValue | currency }}</strong></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center" v-if="type == 'confirmed'"><strong>{{ this.sumAccumulatedRemuneration | currency }}</strong></th>
                    <th class="text-center" v-if="type == 'confirmed'"><strong>{{ this.sumAmmountConsolidated | currency }}</strong></th>
                    <th class="text-center" v-if="type == 'confirmed'"><strong>{{ this.sumSettledPayment | currency }}</strong></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <nav class="table-pagination">
              <ul class="pagination">
                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    v-if="page != 1"
                    @click="page--"
                  >
                    <i class="fas fa-angle-left"></i>
                  </button>
                </li>

                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    v-for="(pageNumber, index) in pages.slice(page - 1, page + 4)"
                    :key="index"
                    @click="page = pageNumber"
                    :class="{ 'is-active': page === pageNumber }"
                  >
                    {{ pageNumber }}
                  </button>
                </li>

                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    v-if="page < pages.length"
                    @click="page++"
                  >
                    <i class="fas fa-angle-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
            <div class="export-button" v-if="isAllOrdersPage">
              <button
                class="btn btn-primary"
                @click="downloadFile('xlsx')"
                :disabled="!displayedData.length"
              >
                <i class="tim-icons icon-cloud-download-93"></i>
                Exportar para Excel
              </button>
              <button
                class="btn btn-primary"
                @click="downloadFile('csv')"
                :disabled="!displayedData.length"
              >
                <i class="tim-icons icon-cloud-download-93"></i>
                Exportar para CSV
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <div class="row">
      <div class="export-button">
        <button
          class="btn btn-primary"
          @click="download"
          :disabled="!displayedData.length"
          :hidden="!prazo"
        >
          <i class="tim-icons icon-cloud-download-93"></i>
          Exportar para Excel
        </button>
      </div>
    </div> -->
    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showSolicitarDevolucao },
            { 'd-none': !showSolicitarDevolucao },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showSolicitarDevolucao"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showSolicitarDevolucao"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Por favor, informe o motivo da devolução.
              </p>

              <textarea
                type="text"
                cols="100"
                rows="5"
                class="form-control"
                v-model="item_modal.devolucao_observacao"
              >
              </textarea>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Fechar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px; 
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarSolicitacaoDevolucao()"
                >
                  Salvar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarDevolucaoRealizada()"
                >
                  {{ processing ? 'Aguarde...' : 'Confirmar devolução'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showAtualizarContrato },
            { 'd-none': !showAtualizarContrato },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showAtualizarContrato"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showAtualizarContrato"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>
              
              <p class="modal-content-description">
                Tem certeza que deseja atualizar o contrato de investimento (Essa ação não poderá ser revertida)?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;"
                  @click="salvarAtualizarContrato()"
                >
                {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showDesfazerPagamento },
            { 'd-none': !showDesfazerPagamento },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showDesfazerPagamento"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showDesfazerPagamento"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Tem certeza que deseja desfazer o pagamento?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }" 
                  style="margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarDesfazerPagamento()"
                >
                {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showInformarLiquidacao },
            { 'd-none': !showInformarLiquidacao },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showInformarLiquidacao"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showInformarLiquidacao"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal pb-4">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <div class="form-group">
                <label for="" class="control-label">Data da liquidação *</label><br />
                <birth-datepicker
                  v-model="item_modal.data_informacao_liquidacao"
                  v-mask="'##/##/####'"
                  :valueIsString="true"
                  :high="true"
                  :hideHeader="true"
                  :attachment="'bottom'"
                  :class="'Password__field'"
                  :delimiter="'/'"
                  :placeholder="'Ex: 27/12/2022'"
                  :locale="[
                    'Jan',
                    'Fev',
                    'Mar',
                    'Abr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Set',
                    'Out',
                    'Nov',
                  ]"
                />
              </div>
              <div class="form-group text-center">
                <label>Cálculo manual:</label><br/>
                <currency-input
                  v-model="
                    valor_liquidacao_manual
                  "
                  class="input"
                  currency="BRL"
                  locale="pt-br"
                  placeholder="Informe o valor"
                  :distraction-free="false"
                />
              </div>
              <p class="modal-content-description">
                Tem certeza que deseja informar a liquidação da CCB, no valor de <br/><b>{{ (valor_liquidacao_manual || this.item_modal.valor_liquidacao) | currency }}?</b>
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;"
                  @click="salvarInformarLiquidacao()"
                >
                  {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showDesfazerLiquidacao },
            { 'd-none': !showDesfazerLiquidacao },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showDesfazerLiquidacao"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showDesfazerLiquidacao"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Tem certeza que deseja cancelar a liquidação anterior da CCB?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarDesfazerLiquidacaoCCB()"
                >
                {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showProcessarPagamento },
            { 'd-none': !showProcessarPagamento },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showProcessarPagamento"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showProcessarPagamento"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Tem certeza que deseja processar o pagamento da CCB junto ao Banco, no valor de R$ {{ this.item_modal.valor_liquidacao | currency }}?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarProcessarPagamentoCCB()"
                >
                {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showCancelarCCB },
            { 'd-none': !showCancelarCCB },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showCancelarCCB"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showCancelarCCB"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Tem certeza que deseja cancelar a CCB atual? Essa operação não poderá ser revertida?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarCancelarCCB()"
                >
                {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showHistory },
            { 'd-none': !showHistory },
          ]"
          v-show="showHistory"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showHistory"
        >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card card p-4" style="min-width: 655px !important;"><!---->
                        <div class="card-header">
                          <div class="modal-header">
                            <button 
                              type="button" 
                              style="
                                color: #288cf7;
                              "
                              @click="closeModal()" 
                              class="close" 
                              data-dismiss="modal" 
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <h5 class="card-title">Histórico de Transações</h5>
                        </div>
                        <div class="card-body">
                          <h4 v-if="item_history.length === 0">
                            Nenhum histórico encontrado para o registro atual.
                          </h4>
                          <div v-if="item_history.length > 0" class="table-responsive">
                            <table class="table tablesorter">
                              <thead class="text-primary">
                                <tr>
                                  <th class="text-center">Data/Hora</th>
                                  <th class="text-center">Operação</th>
                                  <th class="text-center">Usuário</th>
                                  <th class="text-center">Endereço IP</th>
                                </tr>
                              </thead>
                              <tbody class="">
                                <tr v-for="(item, index) in item_history" :key="index">
                                  <td class="text-center">
                                    {{item.transaction_date | unix-to-br-timestamp}}
                                  </td>
                                  <td class="text-center">
                                    {{item.subject}}
                                  </td>
                                  <td class="text-center">
                                    {{item.user_name}}
                                  </td>
                                  <td class="text-center">
                                    {{item.user_ip}}
                                  </td>
                                </tr>
                                <!-- <tr v-if="item_history.length === 0">
                                  <td colspan="4" class="text-center">
                                    Nenhum histórico encontrado para o registro atual.
                                  </td>
                                </tr> -->
                              </tbody>
                            </table>
                          </div>
                        </div><!----><!---->
                      </div>
                    </div>
                  </div>  
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>
  </div>
</template>

<style>
  .broker-title {
    color: #0d143c;
    font-weight: 500;
    font-size: 1.3rem !important;
    margin-bottom: 10px;
  }
</style>
