import moment from 'moment-timezone';

export default order => {
    //Calculo de remuneracao deve considerar D+30 a partir do pagamento do aporte
    if (!order) return 0
    console.log({order})
    if (order.status === 'Pago' || order.status === 'Liquidado') {
        var today;

        const data_pagamento = moment.unix(order.data_pagamento);
        const data_vencimento = moment.unix(order.data_vencimento);
        const data_liquidacao = moment.unix(order.data_liquidacao);

        //Se já foi liquidado congela o cálculo de remuneracao
        if (order.data_liquidacao) {
            today = data_liquidacao;
        } else if (order.data_vencimento){
            if (data_vencimento > moment())
                today = moment()
            else today = data_vencimento
        } else {
            today = moment()
        }
        
        const months = today.diff(data_pagamento, 'month');

        console.log({today, data_pagamento, data_vencimento, data_liquidacao, months})

        var prazoAtual =  0
        if (months > parseInt(order.financing_details.fields['prazo_oferta'].value)) 
            prazoAtual = parseInt(order.financing_details.fields['prazo_oferta'].value)
        else prazoAtual = months
        
        console.log(order.item.value, order.financing_details.fields['taxa_juros'].value, prazoAtual)
        const juros = calcularJurosCompostos(order.item.value, (order.financing_details.fields['taxa_juros'].value).toString().replace(',','.'), prazoAtual)

        return juros;

    } else {
        return 0
    }
    
};

export const calcularJurosCompostos = (valorAporte, taxaJuros, prazo) => {
    let investimento_inicial = parseFloat(valorAporte);
    var meses = parseInt(prazo);
    let taxa_de_juros = parseFloat(taxaJuros.toString().replace(',','.'));

    console.log({investimento_inicial, meses, taxa_de_juros, prazo, taxaJuros})

    let investimento_acumulado = investimento_inicial;

    var total_investimento_acumulado = investimento_inicial;

    let juros_compostos = 0;
    let juros_compostos_total = 0;

    for (let i = 0; i < meses; i++) {
        juros_compostos = (investimento_acumulado * taxa_de_juros) / 100;
        juros_compostos_total = juros_compostos_total + juros_compostos;
        investimento_acumulado = investimento_acumulado + juros_compostos;
        // console.log(i, juros_compostos, juros_compostos_total, investimento_acumulado);
    }
    
    return juros_compostos_total;
};
