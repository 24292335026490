import firebase from 'firebase';
import { withLog } from '../../logger/with_log';
import axios from 'axios';

export default {
  operationalsRef() {
    return firebase.firestore().collection('pessoas');
  },

  async getOperationals() {
    const pessoas = await withLog(() => this.operationalsRef().get());
    const pessoas_to_return = [];

    pessoas.forEach((element) => {
      element.data().caracteristicas.forEach((caract) => {
        if (caract == 'gestor') {
          pessoas_to_return.push(element);
        }
      });
    });
    return pessoas_to_return;
  },

  async createNewOperational(operational) {
    await withLog(() => this.operationalsRef().add(operational));
  },

  async getOperational(email) {
    return await withLog(() =>
      this.operationalsRef()
        .where('email.email', '==', email)
        .get()
        .then((response) => {
          return response;
        }),
    );
  },

  async getOperationalById(id) {
    return await withLog(() => this.operationalsRef().doc(id));
  },

  async updateOperational(id, operational) {
    await withLog(() => this.operationalsRef().doc(id).update(operational));
  },

  async deleteOperational(operational) {
    await withLog(() => this.operationalsRef().doc(operational).delete());
  },

  async updateEmail(email, oldEmail) {
    const response = await axios.post(
      `${process.env.VUE_APP_ENDPOINT_WS}updateUserEmail`,
      { email, oldEmail },
      {
        timeout: 60000,
      },
    );

    return response.data;
  },
};
