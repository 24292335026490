<script>
import {vueTopprogress} from 'vue-top-progress';
import NoticiasMercadoTopBar from '@/components/NoticiasMercado/NoticiasMercadoTopBar.vue'; 
import videoService from '@/services/VideoContent/videoContentService';

const tableColumns = ['Nome'];

export default {
  components: {
    vueTopprogress,
    NoticiasMercadoTopBar
},
  data() {
    return {
      title: '',
      isLoading: false,
      searchTerm: '',
      table: {
        title: 'Notícias - Videos',
        columns: [...tableColumns],
      },
      isLoading: true,
      videos: []
    };
  },
  computed: {
      loggedInUser() {
      return this.$store.getters.isLoggedIn;
      },
      getRole() {
        const filteredRole = this.$store.getters.userClaim.role != 'assessor' ? 'clients' : 'brokers';
        
        if (this.$store.getters.userClaim.role === 'master' || this.$store.getters.userClaim.role === 'gestor') {
          return this.$store.getters.userClaim.role;
        }

        return filteredRole;
      },
  },
  mounted() {
    if(this.$route.query.o == 'latest') {
      this.title = 'Novos Conteúdos'
    }
    if(this.$route.query.o == 'popular') {
      this.title = 'Mais Populares'
    }
    if(this.$route.query.o == 'search') {
      this.searchTerm = this.$route.query.q;
      this.title = 'Resultados para a pesquisa: ' + this.searchTerm
    }
    if(this.$route.query.o == 'favs') {
      this.searchTerm = this.$route.query.q;
      this.title = 'Meus Favoritos'
    }
    this.loadVideosList();
  },
  methods: {
    openDetails(id) {
      this.$router.push(`market-news-video-details/${id}`) 
    },
    async loadVideosList() {
      this.isLoading = true;
      this.videos = [];
      let fullVideosObject = [];


      if(this.$route.query.o == 'search') {
        fullVideosObject = await videoService.getSearchVideos(this.searchTerm, 250, this.getRole);
      } else if(this.$route.query.o == 'popular') {
        fullVideosObject = await videoService.getMostPopularVideos(250, this.getRole);
      } else if (this.$route.query.o == 'latest') {
        fullVideosObject = await videoService.getMostRecentVideos(250, this.getRole);
      } else if (this.$route.query.o == 'favs') {
        fullVideosObject = await videoService.getFavVideos(this.loggedInUser.email, this.getRole);
      }

      if (this.getRole === 'master' || this.getRole === 'gestor') {
        fullVideosObject = await videoService.getAll();
      }

      for(let i = 0; i < fullVideosObject.length; i++) {
          let v = fullVideosObject[i];
          let url = '@assets/images/no-thumbnail.jpg';
          if(v.thumbnail) {
            let thumb = JSON.parse(v.thumbnail);
            url = await videoService.urlThumbnail(thumb.name);
          }

          let viewed = false;
          if(fullVideosObject[i].views && fullVideosObject[i].views.includes(this.loggedInUser.email)) {
            viewed = true;
          }

          this.videos.push({
            id: v.id,
            title: v.title,
            thumbnail: url,
            totalViews: (v.total_views || 0),
            viewed: viewed
          })
      }

      this.isLoading = false;
    }
  }
};
</script>

<template>
  <div>
    <noticias-mercado-top-bar></noticias-mercado-top-bar>
    <div class="row">
      <div class="col-md-12">
        <h3 class="noticias-big-title">{{title}}</h3>
      </div>
    </div>
    <div class="row" v-if="isLoading">
      <div class="col-md-12 loading-gif">
        <img src="@/assets/images/loading.gif">
      </div>
    </div>
    <div class="row" v-if="!isLoading">
      <div class="col-md-12">
        <label v-if="videos.length == 0" class="no-videos">Não foram encontrados videos para a pesquisa.</label>
      </div>
    </div>
    <div class="videos">
      <!-- Lista de videos -->
      <div class="content-video" v-for="item in videos" v-bind:key="item">
        <div class="list-video-item" v-on:click="openDetails(item.id)">
          <div class="list-video-item-thumbnail">
            <label class="assistido" v-if="item.viewed">Assistido</label>
            <img :src="item.thumbnail" alt="" class="img">
            <img src="@/assets/images/start-128.png" class="start"/>
            <img src="@/assets/images/no-thumbnail.jpg" v-if="item.thumbnail == ''" class="img"/>
          </div>
          <div class="list-video-item-title">
            <label>{{item.title}}</label>
          </div>
          <div class="list-video-item-details">
            <label>{{item.totalViews}} Visualizações</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.videos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.content-video {
  display: flex;
  justify-content: center;
}

.assistido {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0,0,0,.5);
  color:#fff;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px;
  border-top-right-radius: 8px;
}

  .no-videos {
    padding-top: 10px;
    font-size: 1rem;
    text-align: center;
  }

  .loading-gif {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-video-item {
    margin: 40px 0px 0px 0px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
  }

  .list-video-item-thumbnail {
    width: fit-content;
    border-radius: 10px;
    max-height: fit-content;
    min-height: fit-content;
    box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
    position: relative;
  }  

  .list-video-item-thumbnail .img{
    border-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .list-video-item-title {
    padding: 8px 0px 0px 0px;
    text-align: left;
    font-size: 0.9rem;
    color: #333;
  }

  .list-video-item-details {
    font-size: 0.9rem;
    color: #555;
  }

  .list-video-item-thumbnail .start {
    position: absolute;
    width: 60px;
    height: 60px;
    opacity: 0.4;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
  }

  .list-video-item-thumbnail:hover .start {
    opacity: 0.8;
  }

  .noticias-big-title {
    font-weight: bold;
    color: darkblue;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin: 0px;
  }
</style>