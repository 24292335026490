import firebase from 'firebase';
import { withLog } from '../../logger/with_log';
import axios from 'axios';
import claimsService from '@/services/Authentication/claimsService';

export default {
  brokersRef() {
    return firebase.firestore().collection('brokers');
  },

  sheetRef() {
    return firebase.firestore().collection('sheet');
  },

  brokerLeadersRef() {
    return firebase.firestore().collection('broker-leaders');
  },

  storageRef() {
    return firebase.storage().ref('brokerImage/');
  },

  async getAllBrokers() {
    return withLog(async () => {
      const snapshot = await this.brokersRef().orderBy('name', 'asc').get();

      return snapshot.docs.map((doc) => doc.data());
    });
  },

  async getLastBrokerPosition() {
    return withLog(async () => {
      const snapshot = await this.brokersRef().orderBy('position', 'asc').get();
      const docs = snapshot.docs.map((doc) => doc.data());
      const positions = docs.map(doc => doc.position);

      return positions[positions.length - 1];
    });
  },

  async getAllBrokerLeaders() {
    return withLog(async () => {
      const snapshot = await this.brokerLeadersRef().orderBy('name', 'asc').get();

      return snapshot.docs.map((doc) => doc.data());
    });
  },

  async getBroker(id) {
    const snapshot = await withLog(() => this.brokersRef().doc(id).get());
    return snapshot.data()
  },

  async getBrokerByEmail(email) {
    const snapshot = await withLog(() => this.brokersRef().where('email', '==', email).get());
    if (!snapshot) return;
    return snapshot.docs[0].data();
  },

  async createNewBroker(broker) {
    const docRef = this.brokersRef().doc();
    const brokerWithId = {
      ...broker,
      status: broker.status ? 'ATIVO' : 'INATIVO',
      id: docRef.id
    };
    await withLog(() => docRef.set(brokerWithId));
  },

  async updateBroker(brokerId, broker) {
    return withLog(() => this.brokersRef().doc(brokerId).update(broker));
  },

  async updateBrokerByEmail(broker, email) {
    withLog(async () => {
      const snapshot = await this.brokersRef()
        .where('email', '==', email)
        .get()
        .then((snapshot) => {
          return !snapshot.empty ? snapshot.docs[0] : null;
        });
      if (snapshot == null) throw 'broker not-found';
      await snapshot.ref.update(broker);
    });
  },

  async deleteBroker(brokerId) {
    await withLog(() => this.brokersRef().doc(brokerId).delete());
  },

  async getBrokerSheet(email) {
    const broker = await this.getBrokerByEmail(email);

    const snapshot = await this.sheetRef().where('broker_id', '==', broker.id).get();
    console.log(broker.id)

    if (!snapshot.docs[0]) return;

    return snapshot.docs[0].data();
  },

  async uploadImageToStorage(file, oldFileName = null) {
    if (oldFileName) {
      const oldFileRef = this.storageRef().child(oldFileName);
      await oldFileRef.delete();
    }

    const fileRef = this.storageRef().child(file.name);
    const snapshot = await fileRef.put(file);

    return snapshot;
  },

  async deleteImageInStorage(filename) {
    const oldFileRef = this.storageRef().child(filename);
    await oldFileRef.delete();
  },

  async getDownloadURL(fileName) {
    const fileRef = this.storageRef().child(fileName);
    try {
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error getting download URL:", error);
      return null;
    }
  },

  async updateBrokerEmail(email, id, oldEmail) {
    let claim = await claimsService.getUserClaimByEmail(oldEmail);
    if ( claim.size == 0 ) {
      throw new Error("E-mail não encontrado");
    }
    else {
      let uid = claim.docs[0].data().uid;

      const response = await axios.post(
        `${process.env.VUE_APP_ENDPOINT_WS}updateEmailBroker`,
        { email, id, uid, oldEmail },
        {
          timeout: 60000
        }
      );

      return response.data;
    }
  },

  async sendMailBroker(name, email, password) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + "sendMailBroker";
    await axios.post(endpoint, {
      email,
      name,
      password,
    }, {
      timeout: 50000,
    });

    await this.updateBrokerByEmail({ email_received: true }, email);
  },
}