/* eslint-disable require-jsdoc */
import firebase from 'firebase';
import { withLog } from '@/logger/with_log';

export default {
  collection() {
    return firebase.firestore().collection('playlists');
  },

  async getAll() {
      const snapshot = await this.collection().orderBy('title', 'asc').get();
      if (snapshot.empty) {
        return [];
      }
      return snapshot.docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }));
  },

  async update(id, data) {
    await this.collection().doc(id).update(data);
  },

  async get(id) {
    return withLog(() => {
      let video = this.collection()
          .doc(id)
          .get()
          .then((snapshot) => (snapshot ? snapshot.data() : null));

      return video;
    });
  },

  async add(data) {
    return await withLog(async () => {
      return this.collection().add(data)
    });
  },

  async delete(id) {
    await withLog(() => this.collection().doc(id).delete());
  },

  async findPlaylistsByTag(tag, type = 'Cliente'){
    let playlists;
    if (type === 'Admin') {
      playlists = await this.collection()
        .where('active', '==', 'Sim')
        .where('tag', '==', tag)
        .orderBy('title', 'asc')
        .get();
    } else {
      playlists = await this.collection()
        .where('active', '==', 'Sim')
        .where('tag', '==', tag)
        .where('broker_type', 'array-contains', type)
        .orderBy('title', 'asc')
        .get();
    }

    if(!playlists) return [];

    const sortedPlaylists = playlists.docs
    .map(doc => doc.data())
    .sort((a, b) => {
      const aNumber = parseInt(a.title, 10);
      const bNumber = parseInt(b.title, 10);

      return aNumber - bNumber;
    });

    return sortedPlaylists;
  },

  async findPlaylistByTitle(title) {
    const videos = await this.collection()
      .where('active', '==', 'Sim')
      .where('title', '==', title)
      .get();

    if(!videos) return [];

    return videos.docs[0].data();
  },

  async removeVideoFromPlaylist(videoInPlaylist) {
    const video = {
      id: videoInPlaylist.item.id,
      name: videoInPlaylist.item.title,
    }

    const snapshot = await this.collection().where('linked', 'array-contains', video).get();
    const playlists = snapshot.docs.map(doc => {
      const playlist = {
        id: doc.id,
        ...doc.data()
      };

      return playlist;
    });

    const updatedPlaylists = playlists.map(playlist => {
      const newLinked = playlist.linked.filter(videoInPlaylist => videoInPlaylist.id !== video.id);

      if (newLinked && newLinked.length > 0) {
        playlist.linked = newLinked;
      }

      return playlist;
    });

    for (const playlist of updatedPlaylists) {
      await this.update(playlist.id, playlist);
    }
  },

  //dashboard services
  async getMostRecentPlaylists(top = 3) {
    const videos = await this.collection()
      .where('active', '==', 'SIM')
      .orderBy('changedTime', 'desc')
      .get();

    console.log(videos)

    return videos;
  },

  async getPlaylistsLikedToVideo(videoId, top = 3) {
    const videos = await this.collection()
      .where('active', '==', 'Sim')
      .orderBy('changedTime', 'desc')
      .limit(top)
      .get();

    let validVideos = [];

    let vv = videos.docs.map((e) => ({
      id: e.id,
      ...e.data(),
    }));

    
    for(let p = 0; p < vv.length; p++) {
      let playlist = vv[p];
      let addAllVideos = true;

      for(let v = 0; v < playlist.linked.length; v++) {
        let video = playlist.linked[v];
        //console.log(video)

        //first we check to see if the video is inside the collection
        //firebase does not allow to search complex object so we need to do this hack
        if(video.id == videoId) {
          addAllVideos = true;
          break;
        }
      }

      //if the current video was found inside the playlist add all videos but the current video the 
      //user is watching
      if(addAllVideos) {
        for(let v = 0; v < playlist.linked.length; v++) {
          let video = playlist.linked[v];
          //console.log(video)
          //add all videos but the one user is watching
          if(video.id != videoId) {
            validVideos.push(video);
          }
  
          //if we reach limit we return 
          if(validVideos.length == top) {
            return validVideos;
          }
        }
      }
    }

    //console.log(validVideos)
    return validVideos;
  }
  
};
