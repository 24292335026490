<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue-file-agent/dist/vue-file-agent.css';

import financingService from '@/services/Financing/financingService';

import Vue from 'vue';
import VueFileAgent from 'vue-file-agent';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import attachmentsService from '@/services/Attachments/attachmentsService';
import pessoasService from '@/services/Pessoas/pessoasService';
import settingsService from '@/services/Settings/settingsService';
import { collectionRef } from '@/firebase';

import { vueTopprogress } from 'vue-top-progress';
import { quillEditor } from 'vue-quill-editor';

import Modal from '@/components/Modal';
import { DoubleBounce } from 'vue-loading-spinner';

import moment from 'moment';

import store from '@/store/index';

import * as municipios from '@/assets/data/municipios.json';

import HelperPlugin from '@/plugins/HelperPlugin';
import {
  CWLStatusCampanha,
  PessoaCaracteristica,
  EnderecoPadrao,
} from '@/store/cwl_enums';
import axios from 'axios';
import { VTooltip } from 'v-tooltip';
import logsService from '@/services/Logs/logsService';

Vue.use(HelperPlugin);
Vue.use(VueFileAgent);
Vue.component('v-select', vSelect);
Vue.directive('tooltip', VTooltip);

import { cnpj } from '@/validate/customValidators.js';

export default {
  components: {
    vueTopprogress,
    quillEditor,
    Modal,
    DoubleBounce,
  },
  data() {
    return {
      adding: false,
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      content: '',
      contrato_personalizado_content: '',
      editorOption: {
        placeholder:
          'Descreva um pouco mais. Lembre-se: aqui é onde você conta sua história para as pessoas apoiarem a sua causa.',
      },
      editorContratoPersonalizadoOptions: {
        placeholder:
          'Digite aqui o contrato personalizado da sua oferta.',
      },
      financing: {
        hasMinimalCapture: false,
      },
      dynamicFields: [],
      relevantInformations: '',
      allSettings: {},
      plataformTotalInvested: 0,
      categorias: [],
      estados: [],
      cidades: [],
      bancos: [],
      modalidades: [],
      showDebtFields: false,
      /**
       * Terms with modal
       */
      showModalTermos: false,
      acceptTerm: false,
      /**
       * Taxas e Prazos with modal
       */
      showModalTaxasPrazos: false,

      /**
       * Publicar automaticamente
       */
      publicarAutomaticamente: false,

      /**
       * Campanha para PJ
       */
      isForPJ: false,
      pessoaPJ: {},
      cnpjForSearch: '',
      isLoadingPessoaPJ: false,
      showPJFields: false,
      blockByPJError: false,

      cepForSearch: '',
      isLoadingEnderecoPessoaPJ: false,
      showPJAddressFields: false,
      skipCepSearch: false,

      isLoadingPJCidades: false,

      fileRecords: [],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      isLoadingCidades: false,

      defaultEncerramentoOfertaDate: '',
      PessoaCaracteristica,

      dados_bancarios: {},
      errorText: {
        type: 'Por favor, selecione uma imagem',
        size: 'As imagens não podem ter mais de 5MB',
      },

      textoDadosBancarios:
        'Para podermos realizar a remessa dos valores, você deve ser o titular da conta abaixo',

      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Pular tutorial',
          buttonPrevious: 'Voltar',
          buttonNext: 'Próximo',
          buttonStop: 'Finalizar',
        },
      },
      steps: [
        {
          target: '.ql-bold', // We're using document.querySelector() under the hood
          header: {
            title: 'Tornar o texto negrito',
          },
          content: `Este botão torna um texto comum em <strong>negrito</strong>!`,
        },
        {
          target: '.ql-link',
          header: {
            title: 'Adicionar link',
          },
          content: 'Este botão permite que você adicione links.',
        },
        {
          target: '.ql-image',
          header: {
            title: 'Adicionar Imagens',
          },
          content: 'Este botão permite que você adicione imagens.',
        },
      ],
    };
  },
  validations: {
    cnpjForSearch: {
      valid: cnpj,
    },
  },
  methods: {
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.content = html;
      this.financing.content = html;
    },
    onContratoPersonalizadoChange({ html }) {
      this.contrato_personalizado_content = html;
      this.financing.contrato_personalizado = html;
    },
    onRelevantEditorChange({ html }) {
      this.relevantInformations = html;
      this.financing.relevantInformations = html;
    },
    calcDaysBetweenDates(initialDate, finalDate) {
      const diffTime = Math.abs(finalDate - initialDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    },
    async verificarCampanhaProntaParaAnalise() {
      const fieldDinamicoEncerramentoOferta =
        this.financing.fields['Encerramento da Oferta'];
      const fieldDinamicoEncerramentoOfertaValue =
        fieldDinamicoEncerramentoOferta.value;
      const fieldDinamicoValor = this.financing.fields['Valor'];
      const fieldDinamicoValorValue = fieldDinamicoValor.value;

      if (
        this.financing.name != '' &&
        this.financing.categoria != null &&
        fieldDinamicoEncerramentoOfertaValue != null &&
        fieldDinamicoEncerramentoOfertaValue != '' &&
        fieldDinamicoValorValue != null &&
        fieldDinamicoValorValue != '' &&
        this.financing.estado != null &&
        this.financing.cidade != null &&
        this.financing.acceptTerm
      ) {
        this.financing.campanha_pronta_para_analise = true;
      }
    },
    async vincularCaracteristica(caracteristica) {
      const pessoa = store.getters.getPessoa;
      pessoasService.addPersona(pessoa.pessoa_id, caracteristica);
    },
    async add() {
      if (this.adding === true) return;

      this.adding = true;
      const that = this;
      this.$refs.topProgress.start();
      let formattedTax;
      if (this.financing.fields['taxa_juros'] && this.financing.fields['taxa_juros'].value) {
        formattedTax = this.financing.fields['taxa_juros'].value.replace(/,/g, '.');
        this.financing.fields['taxa_juros'].value = formattedTax;
      }

      try {
        if (this.modalidade == 'doacao') {
          await this.vincularCaracteristica(
            PessoaCaracteristica.OrganizadorSocial,
          );
        } else {
          await this.vincularCaracteristica(PessoaCaracteristica.Empreendedor);
        }
        const isBiggerAllPlataform =
          this.plataformTotalInvested > this.allSettings.catchmentLimit;

        const valorArrecadacaoField = this.financing.fields['Valor'];
        const valorArrecadacao = valorArrecadacaoField.value;
        if ( this.modalidade == 'equity' ) {
          if ( this.financing.modalidade && this.financing.modalidade.code == 'debt' ) {
            if ( !this.financing.rentabilidade || this.financing.rentabilidade == '' ) {
              this.$notify({
                title: 'Ooopss',
                message:
                  'Por favor, informe a rentabilidade.',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
              that.$refs.topProgress.done();
              this.adding = false;
              return;
            }
            if ( !this.financing.prazo || this.financing.prazo == '' ) {
              this.$notify({
                title: 'Ooopss',
                message:
                  'Por favor, informe o prazo.',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
              that.$refs.topProgress.done();
              this.adding = false;
              return;
            }
          }
        }

        if (
          this.financing.hasMinimalCapture &&
          this.financing.minimalCapture >= valorArrecadacao
        ) {
          this.$notify({
            title: 'Ooopss',
            message:
              'Valor da Captação Mínima não pode ser maior que Valor da Arrecadação.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          that.$refs.topProgress.done();
          return;
        }

        if (!isBiggerAllPlataform) {
          const pessoaLogadaFirebase = await pessoasService.getPessoa(
            this.currentPessoa.pessoa_id,
          );
          const pessoaLogada = pessoaLogadaFirebase.data();
          pessoaLogada.id = pessoaLogadaFirebase.id;
          this.financing.users.created_by = pessoaLogada;
          this.financing.dates.created_at = moment().format(
            'YYYY/MM/DD HH:mm:ss',
          );

          if (this.modalidade == 'equity') {
            this.financing.hasMinimalCapture = true;
            const valorCaptacao = this.financing.fields['Valor'];
            this.financing.minimalCapture = (valorCaptacao.value / 3) * 2;
          }

          const fieldDate = this.financing.fields['Encerramento da Oferta'];
          const initialDate = new Date();
          const finalDate = new Date(fieldDate.value);

          this.financing.dates.end_at = moment(
            fieldDate.value,
            'YYYY-MM-DD',
          ).format('YYYY/MM/DD');

          const diffDays = this.calcDaysBetweenDates(initialDate, finalDate);

          if (diffDays < this.allSettings.maxCampaignDays) {
            this.financing.status = CWLStatusCampanha.EmCriacao;
            this.financing.acceptTerm = true;

            this.verificarCampanhaProntaParaAnalise();

            /**
             * Dados bancários
             */
            if (this.dados_bancarios.banco) {
              if (!this.financing.dados_bancarios) {
                this.financing.dados_bancarios = {};
              }
              this.financing.dados_bancarios.banco = this.dados_bancarios.banco;
            }
            if (
              this.dados_bancarios.agencia_numero &&
              this.dados_bancarios.agencia_numero.length > 0
            ) {
              if (!this.financing.dados_bancarios) {
                this.financing.dados_bancarios = {};
              }
              this.financing.dados_bancarios.agencia_numero =
                this.dados_bancarios.agencia_numero;
            }
            if (
              this.dados_bancarios.agencia_digito &&
              this.dados_bancarios.agencia_digito.length > 0
            ) {
              if (!this.financing.dados_bancarios) {
                this.financing.dados_bancarios = {};
              }
              this.financing.dados_bancarios.agencia_digito =
                this.dados_bancarios.agencia_digito;
            }
            if (
              this.dados_bancarios.conta_numero &&
              this.dados_bancarios.conta_numero.length > 0
            ) {
              if (!this.financing.dados_bancarios) {
                this.financing.dados_bancarios = {};
              }
              this.financing.dados_bancarios.conta_numero =
                this.dados_bancarios.conta_numero;
            }
            if (
              this.dados_bancarios.conta_digito &&
              this.dados_bancarios.conta_digito.length > 0
            ) {
              if (!this.financing.dados_bancarios) {
                this.financing.dados_bancarios = {};
              }
              this.financing.dados_bancarios.conta_digito =
                this.dados_bancarios.conta_digito;
            }

            if (this.financing.dados_bancarios) {
              if (this.isForPJ && !this.pessoaPJ.dados_bancarios) {
                this.pessoaPJ.dados_bancarios = this.financing.dados_bancarios;
              } else if (!this.isForPJ && this.currentPessoa) {
                const pessoaLogadaDadosBancarioFirebase =
                  await pessoasService.getPessoa(this.currentPessoa.pessoa_id);
                const pessoaLogadaDadosBancario =
                  pessoaLogadaDadosBancarioFirebase.data();
                if (!pessoaLogadaDadosBancario.dados_bancarios) {
                  pessoaLogadaDadosBancario.dados_bancarios =
                    this.financing.dados_bancarios;
                  await pessoasService.updatePessoa(
                    pessoaLogadaDadosBancarioFirebase.id,
                    pessoaLogadaDadosBancario,
                  );
                }
              }
            }

            /**
             * Campanha para PJ
             */
            this.financing.is_empresa = this.isForPJ;
            if (this.financing.is_empresa) {
              const { channel, ...pessoa } = store.getters.getPessoa;
              console.log(pessoa);  
              const pessoaPJCreated = await pessoasService.updateCompany(
                this.pessoaPJ,
                {
                  responsavel: pessoa,
                },
              );
              this.financing.dados_complementares.empresa = pessoaPJCreated;
            }

            /**
             * Publicar automaticamente
             */
            this.financing.publicar_automaticamente =
              this.publicarAutomaticamente;

            const logUser = {
              user: {
                email: this.currentPessoa.email.email,
              },
            };
            const logContent = this.financing;
            logsService.createNewLog(
              logUser,
              '',
              'Adicionar Campanha',
              '',
              logContent,
            );

            const financingCreated = await financingService.create(
              this.financing,
            );

            await this.$CWLcreateSubconta(financingCreated.id);

            if (this.fileRecords.length > 0) {
              const file = this.fileRecords[0];
              attachmentsService
                .addNewFinancingImageAttachment(
                  file.file,
                  file.file.name,
                  'Financings',
                  financingCreated.id,
                )
                .then(async function (financingDocumentCreated) {
                  financingService
                    .get(financingCreated.id)
                    .then(async function (financingFromFirebase) {
                      const bucket = 'Financings/' + financingFromFirebase.id;
                      const financingCreatedData = financingFromFirebase.data();
                      financingCreatedData.id = financingCreated.id;
                      financingCreatedData.imageURL =
                        await attachmentsService.urlAttachment(
                          financingDocumentCreated.ref.name,
                          bucket,
                        );
                      await financingService.update(
                        financingCreatedData.id,
                        financingCreatedData,
                      );

                      if (that.modalidade == 'doacao') {
                        that.$notify({
                          title: 'A campanha está pronta para ser enviada!',
                          icon: 'tim-icons icon-bell-55',
                          horizontalAlign: 'center',
                          verticalAlign: 'top',
                          type: 'success',
                          timeout: 3000,
                        });
                      } else {
                        that.$notify({
                          title: 'Oferta criada!',
                          icon: 'tim-icons icon-bell-55',
                          horizontalAlign: 'center',
                          verticalAlign: 'top',
                          type: 'success',
                          timeout: 3000,
                        });
                      }

                      that.financing = that.$CWLNewFinanciamentoPadrao();
                      that.$refs.topProgress.done();
                      that.$router.push('financing');
                    });
                });
            } else {
              if (that.modalidade == 'doacao') {
                this.$notify({
                  title: 'A campanha está pronta para ser enviada!',
                  icon: 'tim-icons icon-bell-55',
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'success',
                  timeout: 3000,
                });
              } else {
                this.$notify({
                  title: 'Oferta criada!',
                  icon: 'tim-icons icon-bell-55',
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'success',
                  timeout: 3000,
                });
              }

              this.financing = that.$CWLNewFinanciamentoPadrao();
              this.$refs.topProgress.done();
              this.$router.push('financing');
            }
          } else {
            this.$notify({
              title: 'Ooopss',
              message:
                'Você não pode criar com mais de ' +
                this.allSettings.maxCampaignDays +
                ' dias',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });

            this.$refs.topProgress.done();
          }
        } else {
          this.$notify({
            title: 'Ooopss',
            message: 'Desculpe, ocorreu um erro. Tente novamente.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });

          this.$refs.topProgress.done();
        }
      } catch (error) {
        this.adding = false;
        console.error(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      }
      if (this.adding !== false) {
        setTimeout(() => {
          this.adding = false;  
        }, 5000);
      }
    },
    async getDynamicFields() {
      this.dynamicFields = [];
      this.$refs.topProgress.start();
      const financials = [] //await financingService.getAll();

      // financials.forEach((financial) => {
      //   const newFinancial = financial.data();

      //   this.plataformTotalInvested += newFinancial.invested;
      // });

      var templateCampos = this.modalidade == 'equity' ? this.modalidade : 'Doação';
      if ( this.financing.modalidade ) {
        templateCampos = this.financing.modalidade.code;
      }
      const dynamicFields = await financingService.getDynamicFields(
        templateCampos,
      );

      dynamicFields.forEach((field) => {
        const newField = field.data();

        this.financing.fields[newField.reference] = newField;

        if (newField.isDate) {
          newField.min = new Date().toISOString().slice(0, 10);
          if (
            newField.reference == 'Encerramento da Oferta' &&
            newField.template == 'Doação'
          ) {
            this.financing.fields[newField.reference].value = moment()
              .add(1, 'M')
              .format('yyyy-MM-DD');
          }
        }

        if (
          newField.isEditable &&
          !this.allSettings.overwriteMinGlobalInvestment &&
          newField.reference == 'Investimento Mínimo Permitido'
        ) {
          this.financing.fields[newField.reference].value =
            this.allSettings.minGlobalInvestment;
          newField.readonly = true;
        }

        newField.id = field.id;

        this.dynamicFields.push(newField);
      });

      this.$refs.topProgress.done();
    },
    async getSettings() {
      this.allSettings = await settingsService.getPublicSettings();

      document.getElementById('parsedContentTermosUso').innerHTML =
        this.allSettings.terms.condicoes_termos_uso;

      document.getElementById('parsedContentTaxasPrazo').innerHTML =
        this.allSettings.terms.taxas_prazos;
    },

    async getEstados() {
      this.estados = municipios.estados.map((e) => ({
        name: e.nome,
        uf: e.sigla,
      }));
    },

    async getCategorias() {
      const that = this;

      await collectionRef('categorias')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((categoria) => {
            const obj = categoria.data();
            obj.id = categoria.id;
            that.categorias.push(obj);
          });
        });
    },

    async getBancos() {
      const that = this;

      await collectionRef('banks')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((banco) => {
            const obj = banco.data();
            obj.id = banco.id;
            that.bancos.push(obj);
          });
        })
        .catch((error) => console.error('error', error));
    },

    checkAcceptTerms() {
      this.$refs.accept_term.click();
    },

    openUseTerms() {
      this.showModalTermos = true;
    },

    openTaxasPrazos() {
      this.showModalTaxasPrazos = true;
    },

    setEstadoSelected(value) {
      this.cidades = [];
      this.financing.cidade = null;
      this.isLoadingCidades = true;
      if (!value) {
        this.isLoadingCidades = false;
        return;
      }

      const estado = municipios.estados.find((e) => e.sigla === value.uf);
      this.cidades = estado ? estado.cidades : [];
      this.isLoadingCidades = false;
    },

    setModalidadeSelected(obj) {
      if ( obj ) {
        this.showDebtFields = obj.code == 'debt';
      }
      this.getDynamicFields();
    },

    setPJEstadoSelected(value) {
      this.cidades = [];
      this.pessoaPJ.endereco.cidade = null;
      this.isLoadingPJCidades = true;
      if (!value) {
        this.isLoadingPJCidades = false;
        return;
      }

      const estado = municipios.estados.find((e) => e.sigla === value.uf);
      this.cidades = estado ? estado.cidades : [];
      this.isLoadingPJCidades = false;
    },

    checkIsForPJ() {
      this.blockByPJError = this.isForPJ;

      if (this.isForPJ) {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, a entidade beneficiária deve ser a titular da conta abaixo';
      } else {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, você deve ser o titular da conta abaixo';
      }

      if (!this.isForPJ) {
        this.cnpjForSearch = '';

        this.pessoaPJ.nome = '';
        this.pessoaPJ.endereco = {};
        this.pessoaPJ.dados_complementares = {};
      }
    },

    async changeCNPJ(cnpj) {
      const that = this;

      const cnpjWithoutMask = cnpj
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cnpjWithoutMask.length == 14) {
        this.showPJFields = false;

        if (!this.isLoadingPessoaPJ) {
          this.isLoadingPessoaPJ = true;
          this.initPessoaPJ();

          await pessoasService
            .searchPJByCNPJ(cnpj)
            .then(async function (document) {
              if (document.size == 0) {
                const externalPessoaFound =
                  await pessoasService.searchExternalPJByCNPJ(cnpj);

                if (externalPessoaFound) {
                  that.pessoaPJ = externalPessoaFound;
                  that.pessoaPJ.dados_complementares.responsavel =
                    store.getters.getPessoa;
                } else {
                  that.cepForSearch = '';
                  that.pessoaPJ.documento.numero = cnpj;
                }

                if (that.pessoaPJ.endereco.cep != '') {
                  that.skipCepSearch = true;
                  that.cepForSearch = that.pessoaPJ.endereco.cep;
                  that.showPJAddressFields = true;
                } else {
                  that.showPJAddressFields = false;
                }

                that.blockByPJError = false;
                that.showPJFields = true;
                that.isLoadingPessoaPJ = false;
              } else {
                document.forEach(function (pessoaFoundDocument) {
                  const pessoaFound = pessoaFoundDocument.data();
                  pessoaFound.id = pessoaFoundDocument.id;

                  if (
                    pessoaFound.dados_complementares.responsavel &&
                    store.getters.getPessoa.email
                  ) {
                    if (
                      pessoaFound.dados_complementares.responsavel.email
                        .email// == store.getters.getPessoa.email.email -- COMENTADO, POIS ESTA REGRA VAI DAR PROBLEMA NO FLUXO DO GESTOR REVISAR A CAMPANHA
                    ) {
                      that.pessoaPJ = pessoaFound;

                      if (that.pessoaPJ.endereco.cep != '') {
                        that.skipCepSearch = true;
                        that.cepForSearch = that.pessoaPJ.endereco.cep;
                        that.showPJAddressFields = true;
                      } else {
                        that.showPJAddressFields = false;
                      }

                      that.blockByPJError = false;
                      that.showPJFields = true;
                      that.isLoadingPessoaPJ = false;
                    } else {
                      that.$notify({
                        title: 'Ooopss',
                        message:
                          'Não foi possível efetuar a criação para esta empresa privada ou entidade sem fins lucrativos. Por favor, entre em contato com a plataforma ou desmarque esta opção.',
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'warning',
                        timeout: 10000,
                      });
                      that.blockByPJError = true;
                      that.isLoadingPessoaPJ = false;
                    }
                  } else {
                    that.$notify({
                      title: 'Ooopss',
                      message:
                        'Não foi possível efetuar a criação para esta empresa privada ou entidade sem fins lucrativos. Por favor, entre em contato com a plataforma ou desmarque esta opção.',
                      icon: 'tim-icons icon-bell-55',
                      horizontalAlign: 'center',
                      verticalAlign: 'top',
                      type: 'warning',
                      timeout: 10000,
                    });
                    that.blockByPJError = true;
                    that.isLoadingPessoaPJ = false;
                  }
                });
              }
            });
        }
      } else if (!this.isLoadingPessoaPJ) {
        this.showPJFields = false;
        this.blockByPJError = true;

        this.pessoaPJ.nome = '';
        this.pessoaPJ.endereco = {};
        this.pessoaPJ.dados_complementares = {};

        this.pessoaPJ.documento = {};
      }
    },

    async changeCEP(cep) {
      const cepWithoutMask = cep
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cepWithoutMask.length == 8 && !this.skipCepSearch) {
        this.showPJAddressFields = false;

        if (!this.isLoadingEnderecoPessoaPJ) {
          this.isLoadingEnderecoPessoaPJ = true;
          this.pessoaPJ.endereco = Object.assign({}, EnderecoPadrao);

          const enderecoFound = await pessoasService.searchAddressByZipcode(
            cep,
          );
          if (enderecoFound) {
            this.pessoaPJ.endereco = enderecoFound;
          }

          this.showPJAddressFields = true;
          this.isLoadingEnderecoPessoaPJ = false;
        }
      } else if (!this.isLoadingEnderecoPessoaPJ && !this.skipCepSearch) {
        this.showPJAddressFields = false;
        this.pessoaPJ.endereco = {};
      }
      this.skipCepSearch = false;
    },

    initPessoaPJ() {
      this.pessoaPJ = this.$CWLNewPessoaPadraoPJ();
    },

    async initTour() {
      if (!this.currentPessoa.disableTour) {
        this.$tours['myTour'].start();

        const pessoaLogadaFirebase = await pessoasService.getPessoa(
          this.currentPessoa.pessoa_id,
        );
        const pessoaLogada = pessoaLogadaFirebase.data();
        pessoaLogada.id = pessoaLogadaFirebase.id;
        pessoaLogada.disableTour = true;
        await pessoasService.updatePessoa(
          pessoaLogadaFirebase.id,
          pessoaLogada,
        );

        const objCooke = this.$CWLCreatePessoaForCookie(pessoaLogada);
        this.$store.commit('SET_PESSOA', objCooke);
      }
    },

    contratoPersonalizadoChecked(value) {
      this.financing.possui_contrato_personalizado = value;
      this.contrato_personalizado_content = '';
      this.financing.contrato_personalizado = '';
      this.editorContratoPersonalizado.setText('');
      this.editorContratoPersonalizado.enable(value);
    },
  },
  computed: {
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    editor() {
      return this.$refs.quillEditor.quill;
    },
    editorContratoPersonalizado() {
      return this.$refs.quillEditorContratoPersonalizado.quill;
    },
    currentPessoa() {
      const { channel, ...person } = this.$store.getters.getPessoa

      return person;
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
  },
  mounted() {
    this.isForPJ = this.modalidade == 'equity';
    this.financing = this.$CWLNewFinanciamentoPadrao();

    if ( this.modalidade == 'equity' ) {
      if ( this.submodalidade == '588' ) {
        this.modalidades = [
          {
            name: this.$t('financing.modalityEquity'),
            code: 'equity'
          },
          {
            name: this.$t('financing.modalityDebt'),
            code: 'debt'
          }
        ];
      }
      else if ( this.submodalidade == 'bacen' ) {
        this.modalidades = [
          {
            name: this.$t('financing.modalityBacen'),
            code: 'bacen'
          }
        ];
      }
    }

    this.initPessoaPJ();
    this.getSettings();
    this.getEstados();
    this.getDynamicFields();
    this.getCategorias();
    this.getBancos();
    this.setEstadoSelected(this.financing.estado);
    this.initTour();
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="add()">
        <h5 slot="header" class="title" v-if="modalidade == 'doacao'">
          <router-link
            to="/financing"
            class="fa fa-arrow-left"
            title="Voltar"
          ></router-link
          >Criar Campanha
        </h5>
        <h5 slot="header" class="title" v-if="modalidade == 'equity'">
          <router-link
            to="/financing"
            class="fa fa-arrow-left"
            title="Voltar"
          ></router-link
          >Criar Oferta
        </h5>

        <div class="row" v-if="modalidade == 'equity'">
          <div class="col-md-6">
            <base-input required label="Modalidade*">
              <v-select
                label="name"
                class="style-chooser"
                :options="modalidades"
                v-model="financing.modalidade"
                :components="{ Deselect }"
                @input="setModalidadeSelected"
              ></v-select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <base-input
              label="Nome*"
              placeholder="Ex: Ajuda para novos leitos"
              v-model="financing.name"
              required
            ></base-input>
          </div>

          <div class="col-md-6">
            <base-input required label="Categoria*">
              <v-select
                label="name"
                class="style-chooser"
                :options="categorias"
                v-model="financing.categoria"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Imagem (tamanho sugerido: 512 x 342)</label>
            <VueFileAgent
              v-model="fileRecords"
              :multiple="false"
              :deletable="false"
              :meta="false"
              :accept="'image/*'"
              :maxSize="'5MB'"
              :helpText="'Arraste uma imagem aqui'"
              :errorText="errorText"
            ></VueFileAgent>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-3" v-for="field in dynamicFields" :key="field.id">
            <label v-if="field.type == 'currency'" :for="field.id">{{
              field.label
            }}</label>

            <currency-input
              v-if="field.type == 'currency'"
              :id="field.id"
              class="form-control"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              v-bind:readonly="field.readonly"
              currency="BRL"
              locale="pt-br"
              :auto-decimal-mode="true"
            />
            <base-input
              v-if="field.type != 'currency' && field.type != 'percent' && field.type == 'number'"
              :type="field.type"
              step="0.01"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
            ></base-input>
            <base-input
              v-if="field.type != 'currency' && field.type != 'percent' && field.type != 'number'"
              :type="field.type"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
            ></base-input>
            <base-input
              v-if="field.type != 'currency' && field.type == 'percent'"
              :type="field.type"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              v-mask="['#%', '##%', '###%']"
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input required label="Estado*">
              <v-select
                label="uf"
                class="style-chooser"
                :options="estados"
                v-model="financing.estado"
                :components="{ Deselect }"
                @input="setEstadoSelected"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>

          <div class="col-md-3">
            <base-input required label="Cidade*">
              <v-select
                label="nome"
                class="style-chooser"
                :options="cidades"
                v-model="financing.cidade"
                :components="{ Deselect }"
                :loading="isLoadingCidades"
              >
                <template #spinner="{ loading }">
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  ></div>
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                  <em style="opacity: 0.9; color: #555" v-else
                    >Selecione um <b>estado</b></em
                  >
                </template>
              </v-select>
            </base-input>
          </div>
        </div>

        <div class="row" v-if="modalidade == 'equity' && showDebtFields">
          
          <div class="col-md-3">
            <base-input
              label="Rentabilidade*"
              placeholder="10,00% a.a."
              v-model="financing.rentabilidade"
              required
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input
              label="Prazo*"
              placeholder="6 meses"
              v-model="financing.prazo"
              required
            ></base-input>
          </div>

        </div>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-3">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="financing.hasMinimalCapture"
                />
                Possui captação mínima
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>

          <div class="col-md-3" v-if="financing.hasMinimalCapture">
            <label>Valor da captação mínima</label>
            <currency-input
              class="form-control"
              v-model="financing.minimalCapture"
              placeholder="Ex: 12.000,00"
              currency="BRL"
              locale="pt-br"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Detalhes</label>
            <quill-editor
              ref="quillEditor"
              v-model="content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onEditorChange($event)"
            />
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-12">
            <base-input
              label="Plano de Aplicação/Prestação de Contas"
              v-model="financing.balancete"
              placeholder="Link para Balancete"
              :readonly="!isGestor"
            ></base-input>
          </div>
        </div>

        <div class="row" v-if="modalidade == 'equity'">
          <div class="col-md-12">
            <base-input
              label="Informações Relevantes"
              v-model="financing.informacoes_relevantes"
              placeholder="Link para Informações Relevantes"
              :readonly="!isGestor"
            ></base-input>
          </div>
        </div>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-12 mt-3">
            <base-checkbox v-model="isForPJ" v-on:input="checkIsForPJ">
              Confirmo que possuo autorização da seguinte entidade beneficiária
              para atuar nesta plataforma.
            </base-checkbox>
          </div>
        </div>

        <template v-if="isForPJ">
          <div class="row">
            <div class="col-md-3 mt-3">
              <div class="form-group">
                <base-input
                  label="CNPJ"
                  v-model="cnpjForSearch"
                  placeholder="Informe o CNPJ*"
                  v-mask="['##.###.###/####-##']"
                  v-on:input="changeCNPJ"
                  required
                ></base-input>
                <div
                  style="color: red"
                  v-if="cnpjForSearch != '' && $v.cnpjForSearch.$invalid"
                >
                  Informe um CNPJ válido.
                </div>
                <label v-if="isLoadingPessoaPJ" style="cursor: default"
                  ><i>Pesquisando...</i></label
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mt-3">
              <base-input
                label="Razão Social*"
                v-model="pessoaPJ.nome"
                placeholder="Informe a razão social"
                required
                v-if="showPJFields"
              ></base-input>
            </div>
            <div class="col-md-4 mt-3">
              <base-input
                label="Nome Fantasia*"
                v-model="pessoaPJ.dados_complementares.nome_fantasia"
                placeholder="Informe o nome fantasia"
                required
                v-if="showPJFields"
              ></base-input>
            </div>
            <div class="col-md-4 mt-3">
              <base-input
                label="E-mail*"
                v-model="pessoaPJ.email.email"
                placeholder="Informe o e-mail"
                required
                v-if="showPJFields"
              ></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <base-input
                label="CEP*"
                v-model="cepForSearch"
                placeholder="Informe o CEP do endereço"
                v-mask="['##.###-###']"
                v-on:input="changeCEP"
                required
                v-if="showPJFields"
              ></base-input>
              <label v-if="isLoadingEnderecoPessoaPJ" style="cursor: default"
                ><i>Pesquisando...</i></label
              >
            </div>
            <div class="col-md-3">
              <base-input
                label="Rua*"
                v-model="pessoaPJ.endereco.rua"
                placeholder="Informe a rua do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="Número*"
                v-model="pessoaPJ.endereco.numero"
                placeholder="Informe o número do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="Complemento"
                v-model="pessoaPJ.endereco.complemento"
                placeholder="Informe o complemento do endereço"
                v-if="showPJFields && showPJAddressFields"
              ></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <base-input
                label="Bairro*"
                v-model="pessoaPJ.endereco.bairro"
                placeholder="Informe o bairro do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                required
                label="Estado*"
                v-if="showPJFields && showPJAddressFields"
              >
                <v-select
                  label="uf"
                  class="style-chooser"
                  :options="estados"
                  v-model="pessoaPJ.endereco.estado"
                  :components="{ Deselect }"
                  @input="setPJEstadoSelected"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      <em style="opacity: 0.9; color: #555"
                        ><b>{{ search }}</b> não encontrada</em
                      >
                    </template>
                  </template>
                </v-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input
                required
                label="Cidade*"
                v-if="showPJFields && showPJAddressFields"
              >
                <v-select
                  label="nome"
                  class="style-chooser"
                  :options="cidades"
                  v-model="pessoaPJ.endereco.cidade"
                  :components="{ Deselect }"
                  :loading="isLoadingPJCidades"
                >
                  <template #spinner="{ loading }">
                    <div
                      v-if="loading"
                      style="border-left-color: rgba(88, 151, 251, 0.71)"
                      class="vs__spinner"
                    ></div>
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      <em style="opacity: 0.9; color: #555"
                        ><b>{{ search }}</b> não encontrada</em
                      >
                    </template>
                    <em style="opacity: 0.9; color: #555" v-else
                      >Selecione um <b>estado</b></em
                    >
                  </template>
                </v-select>
              </base-input>
            </div>
          </div>
        </template>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-12 mt-3">
            <base-checkbox
              v-model="publicarAutomaticamente"
              helperContent="Se marcar esta opção, sua campanha será publicada de forma automática depois que a plataforma aprová-la.<br /><br />Se deixar desmarcado, você terá que publicar manualmente após a aprovação."
            >
              Quero que a campanha seja publicada automaticamente após a
              aprovação
            </base-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <base-checkbox v-model="acceptTerm">
              Li e concordo com as
              <span style="color: blue" @click="openUseTerms">
                Condições, Termos de Uso</span
              >
              e
              <span style="color: blue" @click="openTaxasPrazos">
                Taxas e Prazos</span
              >
            </base-checkbox>
          </div>
        </div>

        <button
          @click="add"
          class="btn btn-sm mt-3"
          :disabled="!acceptTerm || blockByPJError || adding"
          v-if="modalidade == 'doacao'"
        >
          Criar minha campanha
        </button>
        <button
          type="submit"
          class="btn btn-sm mt-3"
          :disabled="!acceptTerm || blockByPJError || adding"
          v-if="modalidade == 'equity'"
        >
          Criar minha oferta
        </button>
      </form>

      <modal :show="showModalTermos" @close="showModalTermos = false">
        <div class="term-of-use">
          Estas são nossas <b>Condições</b> e <b>Termos de Uso</b> da
          plataforma. Leia com atenção e qualquer dúvida, entre em contato.
          <button
            style="
              background-color: rgb(255, 255, 255);
              border: 1px solid #aaa;
              border-radius: 20px;
              height: 40px;
              width: 40px;
              position: absolute;
              right: 0;
              top: 10px;
              cursor: pointer;
              color: #888; 
            "
            @click="showModalTermos = false"
          >
            <i class="tim-icons icon-simple-remove"></i>
          </button>
          <br />
          <br />
          <div
            class="card"
            style="
              background-color: #fff !important;
              max-width: 1024px !important;
            "
          >
            <div class="login-form" style="max-width: 1024px">
              <div id="parsedContentTermosUso"></div>
            </div>
          </div>
        </div>
      </modal>

      <modal :show="showModalTaxasPrazos" @close="showModalTaxasPrazos = false">
        <div class="term-of-use">
          Estas são nossas <b>Taxas</b> e <b>Prazos</b> da plataforma. Leia com
          atenção e qualquer dúvida, entre em contato.
          <button
            style="
              background-color: rgb(255, 255, 255);
              border: 1px solid #aaa;
              border-radius: 20px;
              height: 40px;
              width: 40px;
              position: absolute;
              right: 0;
              top: 10px;
              cursor: pointer;
              color: #888;
            "
            @click="showModalTaxasPrazos = false"
          >
            <i class="tim-icons icon-simple-remove"></i>
          </button>
          <br />
          <br />
          <div
            class="card"
            style="
              background-color: #fff !important;
              max-width: 1024px !important;
            "
          >
            <div class="login-form" style="max-width: 1024px">
              <div id="parsedContentTaxasPrazo"></div>
            </div>
          </div>
        </div>
      </modal>
    </card>

    <card>
      <h5 slot="header" class="title">
        Dados Bancários
        <small id="dadosBancariosHelp" class="form-text text-muted">{{
          textoDadosBancarios
        }}</small>
      </h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input required label="Banco">
            <v-select
              label="name"
              class="style-chooser"
              :options="bancos"
              v-model="dados_bancarios.banco"
              :components="{ Deselect }"
              required
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <em style="opacity: 0.9; color: #555"
                    ><b>{{ search }}</b> não encontrado</em
                  >
                </template>
              </template>
            </v-select>
          </base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Agência"
            placeholder="Informe sua agência"
            v-mask="['####']"
            v-model="dados_bancarios.agencia_numero"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Dígito da agência"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.agencia_digito"
            required
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Conta"
            placeholder="Informe o número da sua conta"
            v-mask="['####################']"
            v-model="dados_bancarios.conta_numero"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Dígito da conta"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.conta_digito"
            required
          ></base-input>
        </div>
      </div>
    </card>

    <card v-if="modalidade == 'equity'">
      <h5 slot="header" class="title">
        {{ $t('financing.customContractTitle') }}
      </h5>
      <h5 slot="header" class="subtitle">
        {{ $t('financing.customContractSubtitle') }}
      </h5>

      <base-checkbox v-on:input="contratoPersonalizadoChecked">
        {{ $t('financing.customContractCheck') }}
      </base-checkbox>

      <div class="row">
          <div class="col-md-12 mt-3">
            <quill-editor
              ref="quillEditorContratoPersonalizado"
              v-model="financing.contrato_personalizado"
              :options="editorContratoPersonalizadoOptions"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onContratoPersonalizadoChange($event)"
              :disabled="true"
            />
          </div>
        </div>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
    <v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour>
  </div>
</template>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: calc(2.25rem + 2px);
  border-color: #c1c1c1;
  border-radius: 0.4285rem;
  line-height: 1.42857;
  color: #555;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  text-transform: lowercase;
  font-variant: small-caps;
}
.style-chooser .vs__dropdown-menu {
  margin-top: 3px;
}
</style>
