<template>
    <div class="mais-popular">
      <div class="row padding-bottom">
        <div class="col-md-10">
          <h3 class="noticias-big-title no-padding">Mais populares</h3>
        </div>
        <div class="col-md-2  more-details">
          <label v-on:click="openMore()" class="no-margin more">Ver Mais</label>
        </div>
      </div>

      <div class="row" v-if="isLoading">
        <div class="col-md-12 loading-gif">
          <img src="@/assets/images/loading.gif">
        </div>
      </div>
      <div class="videos">
        <div class="content-video" v-for="item in videos" v-bind:key="item.id">
          <div class="noticias-novos-videos" v-on:click="openDetails(item.id)">
            <label class="assistido" v-if="item.viewed">Assistido</label>
            <img :src="item.thumbnail" alt="" class="img">
            <img src="@/assets/images/start-128.png" class="start"/>
            <div class="legend">
              <div class="row">
                <div class="col-md-12">
                  <label class="title" :title="item.title">{{item.title | truncate(30)}}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="recorder">{{item.recorder}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
</template>

  <script>
  import videoService from '@/services/VideoContent/videoContentService';
  export default {
    name: 'noticias-mercado-mais-popular',
    props: {
    },
    data() {
      return {
        assistido: false,
        isLoading: false,
        videos : [],
        startIcon: ''
      }
    },
    computed: {
      loggedInUser() {
        return this.$store.getters.isLoggedIn;
      },
      getRole() {
        const filteredRole = this.$store.getters.userClaim.role != 'assessor' ? 'clients' : 'brokers';

        return filteredRole;
      },
    },
    mounted() {
      console.log(this.$route.query);
      this.loadVideos();
    },
    methods : {
      openDetails(id) {
        this.$router.push(`market-news-video-details/${id}`) 
      },
      openMore(id) {
        this.$router.push(`market-news-videos?o=popular`) 
      },
      async loadVideos() {
        this.isLoading = true;
        const checkVideoQuantity = window.innerWidth <= 766 ? 1 : 3;
        let fullVideosObject = await videoService.getMostPopularVideos(checkVideoQuantity, this.getRole);

        for(let i = 0; i < fullVideosObject.length; i++) {
          let v = fullVideosObject[i];
          let url = '@assets/images/no-thumbnail.jpg';
          if(v.thumbnail) {
            let thumb = JSON.parse(v.thumbnail);
            url = await videoService.urlThumbnail(thumb.name);
          }

          let viewed = false;
          if(fullVideosObject[i].views && fullVideosObject[i].views.includes(this.loggedInUser.email)) {
            viewed = true;
          }

          this.videos.push({
            id: v.id,
            title: v.title,
            recorder: v.recorder,
            thumbnail: url,
            viewed: viewed
          })
        }

        this.isLoading = false;

      }
    }
  };
  </script>

<style>
.videos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.mais-popular .content-video {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 85%;
}

.mais-popular .assistido {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0,0,0,.5);
  color:#fff;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px;
  border-top-right-radius: 8px;
}

.mais-popular .more {
  font-size: 0.9rem!important;
  color: darkblue;

}

.mais-popular .loading-gif {
  display: flex;
  align-items: center;
  justify-content: center;
} 

.mais-popular .noticias-novos-videos .legend .title { 
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
}

.mais-popular .noticias-novos-videos .legend .recorder { 
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
}

.mais-popular .noticias-novos-videos .start {
  position: absolute;
  width: 60px;
  height: 60px;
  opacity: 0.4;
}

.mais-popular .noticias-novos-videos:hover .start {
  opacity: 0.8;
}

.mais-popular .noticias-novos-videos .legend {
  padding-top: 10px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  height: 70px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 0px;
  background-color: rgba(0,0,0,0.4);
}

.mais-popular .noticias-novos-videos {
  border-radius: 8px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
     -moz-box-shadow: 0 10px 6px -6px #777;
          box-shadow: 0 10px 6px -6px #777;
}

.mais-popular .noticias-novos-videos:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 10px 6px -6px #0d143c;
     -moz-box-shadow: 0 10px 6px -6px #0d143c;
          box-shadow: 0 10px 6px -6px #0d143c;
}

.mais-popular .noticias-novos-videos .img {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mais-popular .noticias-big-title {
  font-weight: bold;
  color: darkblue;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin: 0px;
}

.mais-popular .more-details {
  font-weight: normal;
  color: darkblue;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: right;
}

.mais-popular .padding-bottom {
  padding-bottom: 20px;
}

.mais-popular .no-padding {
  padding: 0px;
}

.mais-popular .no-margin {
  margin: 0px;
}

@media (max-width: 766px) {
  .videos {
    grid-template-columns: 1fr;
  }
}
</style>
  