<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import brokersService from '@/services/Brokers/brokersService';
import videoService from '@/services/VideoContent/videoContentService';
import playlistService from '@/services/VideoContent/playlistService';

import firebase from 'firebase/app';

// const admin = require('firebase-admin');
// const stagingServiceAccount = require('../../../../../admin-stage.json');
// const productionServiceAccount = require('../../../../../admin-prod.json');

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      isLoading: true,
      sheet: null,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getBrokerSheet();
  },
  methods: {
    async getBrokerSheet() {
      this.$refs.topProgress.start();
      try {
        const sheet = await brokersService.getBrokerSheet(this.currentPessoa.email.email);
        
        if (sheet) {
          this.sheet = sheet;
        }
      } catch(err) {
        console.log(err);
      } finally {
        this.isLoading = false;
        this.$refs.topProgress.done();
      }
    },
    // async migrateToProd() {
    //   this.isLoading= true;
      

    //   admin.initializeApp({
    //     credential: admin.credential.cert(stagingServiceAccount),
    //     storageBucket: 'staging-project-id.appspot.com'
    //   });

    //   const stagingStorage = admin.storage();

    //   const productionApp = admin.initializeApp({
    //     credential: admin.credential.cert(productionServiceAccount),
    //     storageBucket: 'production-project-id.appspot.com'
    //   }, 'production');

    //   const productionStorage = productionApp.storage();

    //   const [stagingFiles] = await stagingStorage.bucket().getFiles();

    //   console.log(stagingFiles);
    //   // const productionConfig = {
    //   //   apiKey: "AIzaSyBqlETt-dzgWoNAUUwOrYiYA0fhAkbo5BI",
    //   //   authDomain: "ecwl-ajx.firebaseapp.com",
    //   //   projectId: "ecwl-ajx",
    //   //   storageBucket: "ecwl-ajx.appspot.com",
    //   //   messagingSenderId: "524400075760",
    //   //   appId: "1:524400075760:web:a332cb53661fba7632b335",
    //   //   measurementId: "G-NE8592T7V4"
    //   // };

    //   // const productionApp = firebase.initializeApp(productionConfig, 'production');
    //   // const productionFirestore = productionApp.firestore();

    //   // const videosProdRef = productionFirestore.collection('videos');

    //   this.isLoading= false;
    // }
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="`Google sheets`"
          :addItems="false"
          v-if="!this.isLoading"
        >
          <button 
            type="button" 
            class="btn btn-sm mr-4 py-2"
            v-if="this.sheet"
          >
            <a 
              :href="this.sheet.worksheet.url" 
              target="_blank" 
              class="link"
            >
              Visualizar Google Sheet
            </a>
          </button>
          <!-- <button 
            type="button" 
            class="btn btn-sm mr-4 py-2"
            @click="migrateToProd"
            :disabled="isLoading"
          >
            Migrar banco
          </button> -->
          <h2 v-if="!this.sheet">Não há documentos vinculado a sua conta.</h2>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style>
  .link {
    color: #ffffff;
    padding: 20px 0;
    text-decoration: none;
  }

  .link:hover, a:focus {
    color: #ffffff;
  }
</style>
