import Firebase from 'firebase/app';

import claimsService from '@/services/Authentication/claimsService';

import { uuid } from 'vue-uuid';

const GoogleProvider = new Firebase.auth.GoogleAuthProvider();
const FacebookProvider = new Firebase.auth.FacebookAuthProvider();

export default {
  
  async updateUserOnFirebase(emailOld, userObject) {
    let claim = await claimsService.getUserClaimByEmail(emailOld);
    if ( claim.size == 0 ) {
      throw new Error("E-mail não encontrado");
    }
    else {
      let uid = claim.docs[0].data().uid;
      Firebase.auth().getUserByEmail(emailOld)
        .then((userRecord) => {
          return userRecord.user.updateEmail(userObject.email);
          // .updateUser(uid, userObject); // https://firebase.google.com/docs/auth/admin/manage-users#update_a_user
        });
    }
  },

  async loginWithCredentials(email, password) {
    return await Firebase.auth().signInWithEmailAndPassword(email, password);
  },

  async createNewUser(email, password) {
    return await Firebase.auth().createUserWithEmailAndPassword(email, password);
  },

  async getUserByEmail(email) {
    if (email === "") {
      return;
    }
    const signInMethods = await Firebase.auth().fetchSignInMethodsForEmail(email);

    return signInMethods;
  },

  // Ninguém está usando
  async updateUser(user, newUser) {
    Firebase.auth().signInWithEmailAndPassword(user.email, user.password)
        .then(() => {
          Firebase.auth().currentUser.updateEmail(newUser.email);
        });
  },

  async deleteUser(user) {
    Firebase.auth().signInWithEmailAndPassword(user.email.email, user.dados_complementares.password)
        .then(() => {
          Firebase.auth().currentUser.delete();
        });
  },

  async loginWithGoogle() {
    return Firebase.auth().signInWithRedirect(GoogleProvider);
  },

  async loginWithFacebook() {
    return Firebase.auth().signInWithRedirect(FacebookProvider);
  },

  getRedirectResult() {
    return Firebase.auth().getRedirectResult();
  },

  async updatePassword(email) {
    return Firebase.auth().sendPasswordResetEmail(email);
  },

  async getLoggedUser() {
    return new Promise((resolve, reject) => {
      Firebase.auth().onAuthStateChanged(user => {
        if (user) {
          resolve({ userId: user.uid, userEmail: user.email });
        } else {
          resolve(null);
        }
      });
    });
  },

  generateTempPassword() {
    return uuid.v4();
  },
};
