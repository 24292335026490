<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import OrdersTable from '@/components/Orders/OrdersTable';
import getRemuneracaoAcumulada from '../../../functions/getRemuneracaoAcumulada';
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';

export default {
  components: {
    OrdersTable, 
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Aportes Efetivados',
        role: 'customer', 
        columns: ['CCB', 'Valor do Investimento', 'Vencimento', 'Evolução', 'Remuneração Acumulada', 'Valor Atualizado', 'Situação', 'Documentos'],
        data: []
      },
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    investorId() {
      return this.$route.params.id;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      try {
        console.log('Pessoa', this.loggedPessoa);
        const orders = await ordersService.getOrdersByUser(this.loggedPessoa.pessoa_id);

        await orders.forEach(async (order) => {
          const _order = order.data();

          if (_order.status == 'Pago' || _order.status == 'Liquidado') {
            _order.remuneracao_acumulada = getRemuneracaoAcumulada(_order);
            this.table.data.push(_order);
          }
          
        });
        console.log(this.table.data);
        this.isLoading = false;
      } catch (error) { }
    },
  },
};
</script>
    <!-- invested_capital: number;
    accumulated_income: number;
    average_monthly_income: number;
    confirmed_investments: number; -->
<template>
  <div class="orders-list">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card p-4"><!---->
          <div class="card-header">
            <h5 class="card-title">Meus Aportes</h5>
          </div>
          <div class="card-body">  
            <h4 class="text-primary">
              Cliente: {{ loggedPessoa.nome }} 
              <!-- - {{ investorId ? 'Outro' : 'Eu' }} -->
            </h4>
            <h5 class="text-primary">
              CPF: {{ loggedPessoa.documento.numero }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <orders-table
          :title="table.title"
          :data="table.data"
          :role="table.role"
          :columns="table.columns"
          @reloadData="getOrders"
        />
      </div>
    </div> 
    <div class="row">
      <div class="col-md-12 text-center">
        <double-bounce v-if="isLoading"></double-bounce>
      </div>
    </div>
  <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
