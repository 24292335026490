import VueRouter from 'vue-router';
import routes from './routes';
import { ServiceConfig } from '@/services/ServiceConfig/ServiceConfig.js';
import store from '@/store/index';

import Vue from 'vue';
import NotificationPlugin from '@/components/NotificationPlugin';
Vue.use(NotificationPlugin);

const checkToken = async (to, next) => {
  try {
    const response = await ServiceConfig.post('validateDisabledUser', {
      token: store.getters.getToken.token
    });

    store.commit('SET_TOKEN', { token: response.data.token });
  } catch (error) {
    store.commit('RESET', '');

    return next({
      path: '/login',
      query: {
        ref: to.query.ref,
        id: to.query.id,
      }
    });
  }
}

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      // return { selector: to.hash };
      return { x: 0, y: 0 };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // console.log(from);
  // console.log('router to', to);
  // console.log(store.getters.isLoggedIn);
  // console.log("getPessoa:");
  // console.log(store.getters.getPessoa);
  // console.log(Object.keys(store.getters.pessoaLogged));
  if (
    to.name !== 'login' &&
    to.name !== 'register' &&
    to.name !== 'forgot-password'
  ) {
    checkToken(to, next)
  }

  if (
    (!Object.keys(store.getters.isLoggedIn).length > 0 ||
    !Object.keys(store.getters.getPessoa).length > 0) &&
    to.name !== 'login' &&
    to.name !== 'register' &&
    to.name !== 'forgot-password' &&
    to.name !== 'register-ref' &&
    to.name !== 'login-ref'
    ) {

    if (from.name) {
      Vue.prototype.$notify({
        title: 'Ooopss',
        message: 'Sua sessão expirou. Por favor, logue novamente.',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'warning',
        timeout: 3000,
      });
    }
    store.commit('RESET', '');
    return next({
      path: '/login',
      query: {
        redirect: to.path,
        ref: to.query.ref,
        id: to.query.id,
      }
    });
  }

  next();
});

export default router;
